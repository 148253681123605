import RowCheckController from "./row_check_controller";
import { get } from '@rails/request.js'

export default class extends RowCheckController {
  static targets = ["buttonsDiv", "createShipmentButton", "addToShipmentModal"];

  static get values() {
    return {
      selectAllSamples: {
        type: Boolean,
        default: false,
      },
      sampleIds: {
        type: Array,
        default: []
      },
      allSampleIds: {
        type: Array,
        default: []
      },
      workspaceId: String
    };
  }

  findSampleIds(){
    if (this.selectAllSamplesValue){
      return this.allSampleIdsValue
    } else {
      return this.sampleIdsValue
    }
  }

  createNewShipment(event) {
    event.preventDefault();

    if (!event.target.classList.contains("disabled")) {
      const loader = document.createElement('button');
      loader.disabled = true;
      loader.classList.add('btn', 'btn-sm', 'btn-secondary');
  
      const spinner = document.createElement('span');
      spinner.classList.add('spinner-border', 'spinner-border-sm', 'mr-2');
      const text = document.createTextNode('Creating Shipment...');
  
      loader.append(spinner, text);
  
      this.createShipmentButtonTarget.replaceWith(loader);
      const sample_ids = this.findSampleIds();
      if (sample_ids.length > 15){
        get(`/workspaces/${this.workspaceIdValue}/notify_shipment_in_progress?sample_count=${sample_ids.length}`, { responseKind: 'turbo-stream' })
        $(this.addToShipmentModalTarget).modal('hide')
      }
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.dataset.sampleId             
            $.ajax({
              type: "POST",
              url: `/sample_shipments/create_sample_shipment/?sample_ids=${sample_ids}&sample_id=${sample_id}`,
              data: {redirect_to: window.location.href},
              dataType: "script"
            });
          },
        },
      });
    }
  }

  addToPendingShipment(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const loader = document.createElement('button');
      loader.disabled = true;
      loader.classList.add('btn', 'btn-sm', 'btn-secondary');

      const spinner = document.createElement('span');
      spinner.classList.add('spinner-border', 'spinner-border-sm', 'mr-2');
      const text = document.createTextNode('Adding to Shipment...');

      loader.append(spinner, text);

      event.target.replaceWith(loader);
      const sample_ids = this.findSampleIds();
      
      if (sample_ids.length > 15){
        get(`/workspaces/${this.workspaceIdValue}/notify_shipment_in_progress?sample_count=${sample_ids.length}&pending_shipment_id=${event.target.dataset.shipmentId}`, { responseKind: 'turbo-stream' })
        $(this.addToShipmentModalTarget).modal('hide')
      }

      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.dataset.sampleId
            const shipment_id = event.target.dataset.shipmentId            
            $.ajax({
              type: "POST",
              url: `/sample_shipments/add_sample_to_existing_shipment/?sample_ids=${sample_ids}&sample_id=${sample_id}&shipment_id=${shipment_id}`,
              data: {redirect_to:  window.location.href},
              dataType: "script"
            });
          },
        },
      });
    }
  }

  choosePendingShipment(event) {
    event.preventDefault();
    
    if (!event.target.classList.contains("disabled")) {
      let sample_ids = this.findSampleIds()
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.dataset.sampleId
            $.ajax({
              type: "GET",
              url: `/sample_shipments/fetch_pending_shipments/?sample_ids=${sample_ids}&sample_id=${sample_id}`,
              dataType: "script"
            });
          },
        },
      });
    }
  }

  storageRecord(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const sample_ids = this.findSampleIds();
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.getAttribute('data-sample-id')
            var redirect_target = location.href;
            if ($("#turbo-table-filter-form") == null) {
              redirect_target = $("#turbo-table-filter-form").action
            }
            $.ajax({
              type: "GET",
              url: `/samples/${sample_id}/storage_record_modal?sample_ids=${sample_ids}`,
              data: {redirect_to: redirect_target},
              dataType: "script"
            });
          },
        },
      });
    }
  }

  incinerateSamples(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.getAttribute('data-sample-id')
            $.ajax({
              type: "GET",
              url: `/samples/${sample_id}/incinerate_samples_modal?redirect_to=${location.href}`,
              dataType: "script"
            });
          },
        },
      });
    }
  }

  checkedRows(event) {
    const ids = Array.from(event.detail.ids);
    this.sampleIdsValue = ids
    const check_all = event.detail.check_all
    if (check_all && ids.length > 0){
      // the user has selected all the samples in the table
      $("#select-full-collection-prompt").removeClass('d-none')
    } else {
      this.deselectAllSamples();
    }

    if (ids.length == 0) {
      this.hideButtons();
      $(".sample-row-action-button").removeClass('disabled')
    } else {
      $(".sample-row-action-button").addClass('disabled')
      this.hideButtons();
      $("#calculating_compatibility_button").show();
      this.checkShippingCompatibility();
    }
  }

  hideButtons(){
    $("#add_to_shipment_button").hide();
    $("#cannot_commingle").hide();
    $("#store_multiple_button").hide();
    $("#cannot_store").hide();
    $("#cannot_ship_or_store").hide();
  }

  checkShippingCompatibility(){
    let sample_ids = this.findSampleIds()
    $.ajax({
      type: "GET",
      url: `/samples/check_shipping_compatibility?sample_ids=${sample_ids}&workspace_id=${this.workspaceIdValue}&select_all_samples=${this.selectAllSamplesValue}`,
      dataType: "script",
    });
  }

  selectAllSamples(){
    $("#select-full-collection-prompt").addClass('d-none')
    $("#selected-full-collection-notification").removeClass('d-none')
    this.selectAllSamplesValue = true
    this.hideButtons();
    $("#calculating_compatibility_button").show();
    this.checkShippingCompatibility();
  } 
    
  deselectAllSamples(){
    $("#selected-full-collection-notification").addClass('d-none')
    $("#select-full-collection-prompt").addClass('d-none')
    this.selectAllSamplesValue = false
  }
}
