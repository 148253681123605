import { Controller } from "@hotwired/stimulus";

const STORAGE_KEY = "frontdoor-latest";

// Connects to data-controller="mobile--frontdoor"
export default class extends Controller {
  static targets = ["list"];

  static values = {
    savedRecents: { type: Number, default: 3 },
  };

  static classes = ["recentlySelected"];

  connect() {
    const data = window.localStorage.getItem(STORAGE_KEY);
    if (!data) {
      return;
    }

    /** @type {Array<String>} */
    let saved;
    try {
      saved = JSON.parse(data);
    } catch {
      return;
    }

    saved.length = this.savedRecentsValue;

    /** @type {Array<String>} */
    const classes = this.hasRecentlySelectedClass
      ? this.recentlySelectedClasses
      : [];

    saved.reverse().forEach((saved) => {
      const elem = document.getElementById(saved);
      if (!elem) {
        return;
      }

      elem.classList.add(...classes);
      this.listTarget.prepend(elem);
    });
  }

  /**
   * Save that an item was selected recently.
   * @param {Event} ev
   */
  save(ev) {
    const id = ev.target?.id;
    if (!id) {
      return;
    }

    /** @type {Array<String>} */
    let saved;
    try {
      let data = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
      saved = data.filter((saved) => saved !== id);
    } catch {
      saved = [];
    }

    saved.unshift(id);

    if (saved.length > this.savedRecentsValue) {
      saved.length = this.savedRecentsValue;
    }

    const json = JSON.stringify(saved);
    window.localStorage.setItem(STORAGE_KEY, json);
  }
}
