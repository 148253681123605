import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="report-wizard-form"
export default class extends Controller {
  static targets = ["customTimeZoneSelector", "customTimeZoneRadioButton"];

  connect() {
    this.toggleCustomTimeZoneField();
  }

  toggleCustomTimeZoneField() {
    this.customTimeZoneSelectorTarget.classList.toggle("d-none", !this.customTimeZoneRadioButtonTarget.checked);
  }
}
