import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirm-reorder"
export default class extends Controller {
  static values = {
    expected: String,
    inventory: String
  }

  connect() {
    let entered_quantity = document.getElementById("item_quantity")
    let expected_quantity = this.expectedValue
    let quantity_help_text = document.getElementById("reorder-quantity-help-text")
    let quantity_alert = document.getElementById("low-quantity-alert")

    entered_quantity.addEventListener("change", function () {
      if (parseInt(entered_quantity.value) < parseInt(expected_quantity)) {
        quantity_alert.classList.remove('d-none')
        quantity_help_text.classList.add('d-none')
      } else {
        quantity_alert.classList.add('d-none')
        quantity_help_text.classList.remove('d-none')
      }
    })
  }

  deleteReorder(event) {
    event.preventDefault();
    let inventory_hash = this.inventoryValue
    let redirect_target = location.href
    $.ajax({
      type: "POST",
      url: `/inventories/${inventory_hash}/delete_reorder`,
      data: { redirect_to: redirect_target },
      dataType: "script",
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRF-Token", $("meta[name='csrf-token']").attr("content"));
      }
    });
  }
}
