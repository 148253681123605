import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { DirectUpload } from "@rails/activestorage";
import Spinner from "./spinner";

const DIRECT_UPLOAD_ENDPOINT = "/rails/active_storage/direct_uploads";

const Uploader = ({ resourceType, resourceId, from, dropzoneOptions }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const input = document.querySelector(
    "#attachment_uploader_overlay input[type=file]"
  );
  const form = document.querySelector("#attachment_uploader_form");
  const link = document.querySelector("#attach_files_link");

  const setOverlayActive = () => {
    const overlay = document.querySelector("#attachment_uploader_overlay");
    overlay.classList.add("attachment_uploader_overlay_active");
    overlay.classList.remove("attachment_uploader_overlay_inactive");
  };

  const setOverlayInactive = () => {
    const overlay = document.querySelector("#attachment_uploader_overlay");
    overlay.classList.remove("attachment_uploader_overlay_active");
    overlay.classList.add("attachment_uploader_overlay_inactive");
  };

  const onDrop = (files) =>
    acceptedFiles === 0 ? setOverlayInactive() : setOverlayActive();

  const onDragEnter = () => {
    setOverlayActive();
  };

  const onDragLeave = () => {
    setOverlayInactive();
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    noClick: true,
    noKeyboard: true,
    ...dropzoneOptions,
  });

  link.onclick = open;

  useEffect(() => {
    setSelectedFiles([...selectedFiles, Array.from(acceptedFiles)].flat());
    if (acceptedFiles.length > 0) {
      setIsUploading(true);
      uploadFiles();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (
      uploadedFiles.length === acceptedFiles.length &&
      uploadedFiles.length !== 0
    ) {
      form.submit();
    }
  }, [uploadedFiles]);

  useEffect(() => {
    const overlay = document.querySelector("#attachment_uploader_overlay");
    if (!overlay) {
      return;
    }
    if (isUploading) {
      setOverlayActive();
    } else {
      setOverlayInactive();
    }
  }, [isUploading]);

  const uploadFile = (file) => {
    const url = DIRECT_UPLOAD_ENDPOINT;
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = "attachments[]";
        form.appendChild(hiddenField);
        console.log(hiddenField);
        setUploadedFiles((prevUploaded) => [...prevUploaded, file]);
      }
    });
  };

  const uploadFiles = () => {
    acceptedFiles.forEach((file) => uploadFile(file));
  };

  const files = selectedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  return (
    <div
      id="attachment_uploader_overlay"
      className="attachment_uploader_overlay"
    >
      <form
        id="attachment_uploader_form"
        action="/attachments"
        acceptCharset="UTF-8"
        method="post"
        className="attachment_uploader_form"
      >
        <input type="hidden" name="authenticity_token" value={csrf} />
        <input
          type="hidden"
          name="resource_type"
          id="resource_type"
          value={resourceType}
        />
        <input
          type="hidden"
          name="resource_id"
          id="resource_id"
          value={resourceId}
        />
        <input type="hidden" name="from" id="from" value={from} />

        <div {...getRootProps()} className="attachment_uploader_container">
          <input {...getInputProps()} />

          {isUploading && (
            <div className="attachment_uploader_progress_container">
              <Spinner />
              <div className="attachment_uploader_text">
                Attaching files, please wait...
              </div>
            </div>
          )}
          {isDragActive ? (
            <div className="attachment_uploader_dropzone">
              Drop the files here!
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </form>
    </div>
  );
};

Uploader.propTypes = {};
Uploader.defaultProps = {};

export default Uploader;
