import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

export default class AnimatedCounter extends Controller {
  static values = {
    start: Number,
    end: Number,
    duration: Number,
  };

  static numberFormatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
  });

  connect() {
    this.element.replaceChildren();

    const startValue = this.startValue;
    const endValue = this.endValue;

    d3.select(this.element)
      .transition()
      .ease(d3.easeCubicOut)
      .duration(this.durationValue)
      .tween("text", function () {
        const text = this;
        const interp = d3.interpolateNumber(startValue, endValue);
        return function (t) {
          const number = AnimatedCounter.numberFormatter.format(interp(t));
          d3.select(text).text(number);
        };
      });
  }
}
