import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    workspaceId: String,
    useScreeningId: Boolean
  }

  static get targets() {
    return ["cohortSelect", "groupSelect", "visitScheduleDiv", "visitSchedulePicker", "subjectIdentifierField", "subjectParam", "subjectMatchIdField", "subjectScreeningIdentifierField", "screeningIdAlertIcon", "enrollSubjectForm"]
  }

  connect(){
  }
  
  subjectIdentifierFieldTargetConnected(elem) {
    this.initializeSubjectField()
  }
  
  subjectScreeningIdentifierFieldTargetConnected(elem) {
    this.initializeSubjectScreeningIdField()
  }
  
  screeningIdAlertIconTargetConnected(elem) {
    $('[data-toggle="tooltip"]').tooltip();
  }

  getVisitSchedules(e) {
    var cohort_id = ""
    var group_id = ""
    var subject_id = ""
    if (this.hasCohortSelectTarget) {
      cohort_id = this.cohortSelectTarget.value
    }
    if (this.hasGroupSelectTarget) {
      group_id = this.groupSelectTarget.value
    }
    if (this.hasSubjectParamTarget) {
      subject_id = this.subjectParamTarget.value
    }
    if (this.hasGroupSelectTarget && group_id == "" && this.hasCohortSelectTarget && cohort_id == ""){
        this.visitScheduleDivTarget.style.display = "none"
        this.visitSchedulePickerTarget.value == ""
    } else {
        get(`/workspaces/${this.workspaceIdValue}/fetch_subject_visit_schedules_for_cohorts_and_groups?group_id=${group_id}&cohort_id=${cohort_id}&subject_id=${subject_id}`, {responseKind: 'turbo-stream'})
    }
  }  

  initializeSubjectScreeningIdField(){
    const workspaceId = this.workspaceIdValue
    var options = {
      placeholder: "Find or create subject by screening identifier",
      adjustWidth: false,
      getValue: "screening_identifier",
      url: function (phrase) {
        return "fetch_subjects?screening=true&s=" + phrase;
      },
      listLocation: "subjects",
      list: {
        maxNumberOfElements: 10,
        match: {
          enabled: true,
        },
        onChooseEvent: function () {
          NProgress.done();
          NProgress.remove();
          get(`/workspaces/${workspaceId}/fetch_subject_status?screening=true&s=${$("#enroll_subject_screening_identifier").val()}`, { responseKind: 'turbo-stream' })
        },
        showAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
        hideAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
      },
      theme: "bootstrap",
      requestDelay: 500,
    };

    $(this.subjectScreeningIdentifierFieldTarget).easyAutocomplete(options);
  }

  initializeSubjectField(){
    const workspaceId = this.workspaceIdValue
    var options = {
      placeholder: "Find or create subject",
      adjustWidth: false,
      getValue: "identifier",
      url: function (phrase) {
        return "fetch_subjects?si=" + phrase;
      },
      listLocation: "subjects",
      list: {
        maxNumberOfElements: 10,
        match: {
          enabled: true,
        },
        onChooseEvent: function () {
          NProgress.done();
          NProgress.remove();
          get(`/workspaces/${workspaceId}/fetch_subject_status?s=${$("#enroll_subject_identifier").val()}`, { responseKind: 'turbo-stream' })
        },
        showAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
        hideAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
      },
      theme: "bootstrap",
      requestDelay: 500,
    };

    $(this.subjectIdentifierFieldTarget).easyAutocomplete(options);

    // fix markup for input group autocomplete
    $(this.subjectIdentifierFieldTarget)
      .closest(".input-group")
      .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-prepend").prependTo(autocomplete);
      if ($(inputGroup).find(".input-group-append")) {
        $(inputGroup).find(".input-group-append").appendTo(autocomplete);
      }
      autocomplete.addClass("input-group");
      });
  }

  manageSubjectFields(event){
    if (this.subjectIdentifierFieldTarget.value == "" && (!this.useScreeningIdValue || this.subjectScreeningIdentifierFieldTarget.value == "")) {
      $("#visit_schedule_picker").hide();
      $("#group_and_cohort_pickers").hide();
    } else if (event.keycode == 13 || this.subjectIdentifierFieldTarget.value.length >= 1) {
      $("#visit_schedule_picker").show();
      $("#group_and_cohort_pickers").show();
      get(`/workspaces/${this.workspaceIdValue}/fetch_subject_status?s=${this.subjectIdentifierFieldTarget.value}&subject_match_id=${this.subjectMatchIdFieldTarget.value}`, { responseKind: 'turbo-stream' })
    }
  }

  manageScreeningFieldChanges(event){
    if (this.subjectScreeningIdentifierFieldTarget.value == "" && this.subjectIdentifierFieldTarget.value == "") {
      $("#visit_schedule_picker").hide();
      $("#group_and_cohort_pickers").hide();
    } else if (event.keycode == 13 || this.subjectScreeningIdentifierFieldTarget.value.length >= 1) {
      $("#visit_schedule_picker").show();
      $("#group_and_cohort_pickers").show();
      get(`/workspaces/${this.workspaceIdValue}/fetch_subject_status?screening=true&s=${this.subjectScreeningIdentifierFieldTarget.value}&subject_match_id=${this.subjectMatchIdFieldTarget.value}`, { responseKind: 'turbo-stream' })
    }
  }

  manageSubmit(event){
    event.preventDefault();
    let standard_identifier = this.subjectIdentifierFieldTarget.value
    let screening = this.subjectScreeningIdentifierFieldTarget.value
    if (standard_identifier == ""){
      this.subjectIdentifierFieldTarget.setAttribute("value", screening)
    }
    this.enrollSubjectFormTarget.submit()
  }
}
