import { Controller } from "@hotwired/stimulus"

const UNUSED_TOOLBAR_CLASSES = [
  ".trix-button--icon-quote",
  ".trix-button--icon-code"
];

export default class extends Controller {
  static get targets() {
    return ["trixWrapper"]
  }

  connect() {
    this.initialize()
  }

  
  initialize(){
    // clean out unused buttons
    UNUSED_TOOLBAR_CLASSES.forEach((cls) => {
      this.trixWrapperTarget.querySelectorAll(cls).forEach((el) => el.remove());
    });
    // links should open in a new tab
    this.trixWrapperTarget.querySelector("trix-editor").querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }
}
