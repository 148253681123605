import { Controller } from "@hotwired/stimulus";
import fuzzysort from "fuzzysort";

// Connects to data-controller="searchable-dropdown"
export default class extends Controller {
  static targets = ["inputForm", "inputField", "entry"];

  static values = {
    minSearchSize: Number,
  };

  connect() {
    this.entryCount = this.entryTargets.length;
    this.preparedEntries = null;
    this.selectedEntryIndex = null;

    this.$menu = $(this.element.closest(".dropdown-menu"));
    this.$dropdown = this.$menu.parent();
    this.$dropdown.on("shown.bs.dropdown", this.focus.bind(this));
    this.$dropdown.on("hidden.bs.dropdown", this.hidden.bind(this));

    this.toggleInput();
  }

  disconnect() {
    this.$dropdown.off("shown.bs.dropdown", this.focus.bind(this));
    this.$dropdown.off("hidden.bs.dropdown", this.hidden.bind(this));
  }

  /**
   * Toggle visibility of the search box based on the min search size.
   */
  toggleInput() {
    const filterVisible =
      !this.hasMinSearchSizeValue ||
      this.entryTargets.length >= this.minSearchSizeValue;

    this.inputFormTarget.classList.toggle("d-none", !filterVisible);
    if (filterVisible) {
      this.$menu.css("min-width", "375px");
    }
    
  }

  /**
   * Focus the text field when dropdown is displayed.
   */
  focus() {
    this.inputFieldTarget.focus();
    this.$menu.width(this.$menu.width());
  }

  /**
   * When dropdown is hidden, reset input and state.
   */
  hidden() {
    this.inputFieldTarget.value = "";
    this.$menu.css("width", "");

    this.reset();
  }

  /**
   * Reset state by deselecting any entries and removing any filters.
   */
  reset() {
    this.selectedEntryIndex = null;

    this.entryTargets.forEach((target) => target.classList.remove("d-none"));
    this.updateSelectedEntry();
  }

  /**
   * Index all entry text content, but only if not already computed.
   */
  prepareEntries() {
    if (this.preparedEntries) return;

    this.preparedEntries = this.entryTargets.map((target, index) => {
      return {
        index,
        textPrepared: fuzzysort.prepare(target.textContent),
      };
    });
  }

  /**
   * Get entries that have not been hidden.
   * @returns {Array<HTMLElement>}
   */
  visibleEntries() {
    return this.entryTargets.filter(
      (target) => !target.classList.contains("d-none")
    );
  }

  /**
   * Update styling for selected result.
   */
  updateSelectedEntry() {
    this.visibleEntries().forEach((target, index) => {
      const active = index === this.selectedEntryIndex;
      target.classList.toggle("active", active);
    });
  }

  /**
   * Perform a filter based on some text input.
   * @param {KeyboardEvent} ev
   */
  filter(ev) {
    this.selectedEntryIndex = null;
    this.updateSelectedEntry();

    if (ev.target.value.length === 0) {
      this.reset();
      return;
    }

    this.prepareEntries();
    const results = fuzzysort.go(ev.target.value, this.preparedEntries, {
      key: "textPrepared",
    });

    const resultIndexes = new Set(results.map((result) => result.obj["index"]));
    this.entryTargets.forEach((target, index) => {
      target.classList.toggle("d-none", !resultIndexes.has(index));
    });
  }

  /**
   * Perform some handling for the keyboard input.
   * @param {KeyboardEvent} ev
   */
  key(ev) {
    const handledKeys = ["ArrowUp", "ArrowDown", "Enter"];
    if (!handledKeys.includes(ev.key)) return;

    ev.preventDefault();

    const entries = this.visibleEntries();

    switch (ev.key) {
      case "ArrowUp":
        if (this.selectedEntryIndex === null) {
          this.selectedEntryIndex = 0;
        } else if (this.selectedEntryIndex > 0) {
          this.selectedEntryIndex -= 1;
        }
        break;

      case "ArrowDown":
        if (this.selectedEntryIndex === null) {
          this.selectedEntryIndex = 0;
        } else if (this.selectedEntryIndex <= entries.length - 2) {
          this.selectedEntryIndex += 1;
        }
        break;

      case "Enter":
        if (this.selectedEntryIndex === null) {
          this.selectedEntryIndex = 0;
        }
        entries[this.selectedEntryIndex].click();
        return;
    }

    const block = this.selectedEntryIndex === 0 ? "end" : "nearest";
    entries[this.selectedEntryIndex].scrollIntoView({
      behavior: "smooth",
      block,
    });

    this.updateSelectedEntry();
  }
}
