import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form--datalist"
export default class extends Controller {
  static targets = ["field", "datalist", "missingReveal"];

  static values = {
    message: { type: String, default: "Please pick an existing value." },
    existing: { type: Boolean, default: true },
  };

  connect() {
    this.missingRevealTargets.forEach((reveal) =>
      reveal.classList.add("d-none")
    );

    this.lastValue = this.fieldTarget.value;
  }

  knownValues() {
    return [...this.datalistTarget.options].map((option) => option.value);
  }

  validate() {
    const value = this.fieldTarget.value;

    // Reset everything when value is empty.
    if (value.length === 0) {
      this.fieldTarget.dispatchEvent(
        new CustomEvent("form--datalist:change", {
          detail: {
            value,
          },
        })
      );

      this.fieldTarget.classList.remove("is-invalid");
      this.fieldTarget.setCustomValidity("");

      this.missingRevealTargets.forEach((reveal) =>
        reveal.classList.add("d-none")
      );

      return;
    }

    const hasValue = this.knownValues().includes(value);
    let isValid = true;

    if (this.existingValue) {
      this.fieldTarget.classList.toggle("is-invalid", !hasValue);
      this.fieldTarget.setCustomValidity(hasValue ? "" : this.messageValue);

      isValid &= hasValue;
    }

    // Only reveal missing target if it's valid to be missing.
    if (isValid && this.hasMissingRevealTarget) {
      this.missingRevealTargets.forEach((reveal) =>
        reveal.classList.toggle("d-none", hasValue)
      );
    }

    const option = [...this.datalistTarget.options].find(
      (option) => option.value === value
    );

    this.fieldTarget.dispatchEvent(
      new CustomEvent("form--datalist:change", {
        detail: {
          knownValue: hasValue,
          isValid: !!isValid,
          value,
          option,
        },
      })
    );
  }

  check({ target }) {
    // Only revalidate when value is now empty or changed by multiple characters.
    const difference = Math.abs(this.lastValue.length - target.value.length);
    if (target.value.length === 0 || difference > 1) {
      this.validate();
    }

    this.lastValue = target.value;
  }

  datalistTargetConnected() {
    // Reset field whenever datalist changes.
    this.fieldTarget.value = "";
  }
}
