import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    staticData: String,
  };

  static targets = ["input"];

  connect() {
    const $input = $(this.inputTarget);
    const opts = {
      url: this.staticDataValue,
      adjustWidth: false,
      list: {
        match: {
          enabled: true,
        }
      }
    };
    $input.easyAutocomplete(opts);
  }
}
