import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    siteId: String
  }
  
  static get targets() {
    return ["markAllDisposedButton", "itemCount"]
  }

  initialize() {
    this.checkedIds = new Set();
  }

  checkedRows(event) {
    this.checkedIds = event.detail.ids;
    this.itemCountTarget.innerHTML = [...this.element.querySelectorAll('tbody tr')].filter((row) => row.querySelector('[data-turbotable-id-param]:checked')).map((row) => parseInt(row.querySelector('td.item-quantity').textContent)).reduce((acc, value) => acc + value, 0);
    this.markAllDisposedButtonTarget.classList.toggle('d-none', this.checkedIds.size === 0);
  }

  markAllExpiredDisposedModal(event) {
    event.preventDefault();
    const ids = Array.from(this.checkedIds)
    const site_id = this.siteIdValue
    if (ids.length) {
      document.getElementById('turbo-modal').src = `/sites/${site_id}/mark_expired_items_disposed_modal/?alert_ids=${ids}`
    }
  }

}
