/*! checkboxes.js v1.2.0 | (c) 2013 - 2016 Rubens Mariuzzo | http://github.com/rmariuzzo/checkboxes.js/LICENSE */ "use strict";
function _classCallCheck(a, b) {
  if (!(a instanceof b))
    throw new TypeError("Cannot call a class as a function");
}
var _createClass = (function () {
  function a(a, b) {
    for (var c = 0; c < b.length; c++) {
      var d = b[c];
      (d.enumerable = d.enumerable || !1),
        (d.configurable = !0),
        "value" in d && (d.writable = !0),
        Object.defineProperty(a, d.key, d);
    }
  }
  return function (b, c, d) {
    return c && a(b.prototype, c), d && a(b, d), b;
  };
})();
!(function (a) {
  var b = (function () {
      function b(a) {
        _classCallCheck(this, b), (this.$context = a);
      }
      return (
        _createClass(b, [
          {
            key: "check",
            value: function () {
              this.$context
                .find(":checkbox")
                .filter(":not(:disabled)")
                .filter(":visible")
                .prop("checked", !0)
                .trigger("change");
            },
          },
          {
            key: "uncheck",
            value: function () {
              this.$context
                .find(":checkbox:visible")
                .filter(":not(:disabled)")
                .prop("checked", !1)
                .trigger("change");
            },
          },
          {
            key: "toggle",
            value: function () {
              this.$context
                .find(":checkbox:visible")
                .filter(":not(:disabled)")
                .each(function (b, c) {
                  var d = a(c);
                  d.prop("checked", !d.is(":checked"));
                })
                .trigger("change");
            },
          },
          {
            key: "max",
            value: function (a) {
              var b = this;
              a > 0
                ? !(function () {
                    var c = b;
                    b.$context.on(
                      "click.checkboxes.max",
                      ":checkbox",
                      function () {
                        c.$context.find(":checked").length === a
                          ? c.$context
                              .find(":checkbox:not(:checked)")
                              .prop("disabled", !0)
                          : c.$context
                              .find(":checkbox:not(:checked)")
                              .prop("disabled", !1);
                      }
                    );
                  })()
                : this.$context.off("click.checkboxes.max");
            },
          },
          {
            key: "range",
            value: function (b) {
              var c = this;
              b
                ? !(function () {
                    var b = c;
                    c.$context.on(
                      "click.checkboxes.range",
                      ":checkbox",
                      function (c) {
                        var d = a(c.target);
                        if (c.shiftKey && b.$last) {
                          var e = b.$context.find(":checkbox:visible"),
                            f = e.index(b.$last),
                            g = e.index(d),
                            h = Math.min(f, g),
                            i = Math.max(f, g) + 1;
                          e.slice(h, i)
                            .filter(":not(:disabled)")
                            .prop("checked", d.prop("checked"))
                            .trigger("change");
                        }
                        b.$last = d;
                      }
                    );
                  })()
                : this.$context.off("click.checkboxes.range");
            },
          },
        ]),
        b
      );
    })(),
    c = a.fn.checkboxes;
  (a.fn.checkboxes = function (c) {
    var d = Array.prototype.slice.call(arguments, 1);
    return this.each(function (e, f) {
      var g = a(f),
        h = g.data("checkboxes");
      h || g.data("checkboxes", (h = new b(g))),
        "string" == typeof c && h[c] && h[c].apply(h, d);
    });
  }),
    (a.fn.checkboxes.Constructor = b),
    (a.fn.checkboxes.noConflict = function () {
      return (a.fn.checkboxes = c), this;
    });
  var d = function (b) {
      var c = a(b.target),
        d = c.attr("href"),
        e = a(c.data("context") || (d && d.replace(/.*(?=#[^\s]+$)/, ""))),
        f = c.data("action");
      e && f && (c.is(":checkbox") || b.preventDefault(), e.checkboxes(f));
    },
    e = function () {
      a("[data-toggle^=checkboxes]").each(function () {
        var b = a(this),
          c = b.data();
        delete c.toggle;
        for (var d in c) b.checkboxes(d, c[d]);
      });
    };
  a(document).on("click.checkboxes.data-api", "[data-toggle^=checkboxes]", d),
    a(document).on("ready.checkboxes.data-api", e);
})(window.jQuery);
