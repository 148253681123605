import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.checkedIds = new Set();
  }

  connect() {
    this.checkedIds = new Set();
  }

  checkedRows(event) {
    this.checkedIds = event.detail.ids;
  }
  
  storeSamples(event) {
    this.checkedIds = event.detail.ids;
  }
}
