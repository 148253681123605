import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "use-http";
import { useKey } from "react-use";
import { useDebouncedCallback } from "use-debounce";

import Result from "./components/Result";

const PLACEHOLDER_TEXT = "Search...";
const MINIMUM_QUERY_LENGTH = 3;
const DEBOUNCE_INTERVAL = 250;
const API_ROOT = "/api/v1";
const API_ENDPOINT = "/search";

const Search = (params) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [query, setQuery] = useState(params["query"] || "");
  const [data, setData] = useState([]);
  const { get, loading, abort } = useFetch(API_ROOT);
  const onSearchDebounced = useDebouncedCallback(
    (value) => onSearch(),
    DEBOUNCE_INTERVAL
  );

  const dataRef = useRef();
  useEffect(() => {
    dataRef.current = data?.results;
  });

  useKey("ArrowUp", () => {
    setSelectedIndex((selectedIndex) => {
      if (selectedIndex === null) {
        return dataRef.current.length - 1;
      } else if (selectedIndex === 0) {
        return null;
      } else if (selectedIndex > 0) {
        return selectedIndex - 1;
      } else {
        return selectedIndex;
      }
    });
  });

  useKey("ArrowDown", () => {
    setSelectedIndex((selectedIndex) => {
      if (selectedIndex === null) {
        return 0;
      } else if (selectedIndex === dataRef.current.length - 1) {
        return null;
      } else if (selectedIndex + 1 < dataRef.current.length) {
        return selectedIndex + 1;
      } else {
        return selectedIndex;
      }
    });
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedIndex === null) {
      document.location = pathForStaticSearch();
    } else {
      document.location = data.results[selectedIndex].path;
    }
  };

  const onSearch = async () => {
    abort();
    setData([]);
    setSelectedIndex(null);

    if (!query || query.length < MINIMUM_QUERY_LENGTH) {
      return;
    }

    const response = await get(pathForLiveSearch());
    if (response?.data?.results) {
      setData(response.data);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      return false;
    } else if (event.key === "Escape") {
      setQuery("");
      setData([]);
    }
  };

  const pathForLiveSearch = () =>
    `${API_ENDPOINT}/${encodeURIComponent(query)}.json`;

  const pathForStaticSearch = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("static_query", query);
    return url;
  };

  return (
    <div id="SearchComponent">
      <form onSubmit={handleSubmit}>
        <div className="search--input-container">
          <i
            className={[
              "fas fa-spinner fa-spin",
              "search--input-spinner",
              loading
                ? "search--input-spinner-active"
                : "search--input-spinner-hidden",
            ].join(" ")}
          ></i>
          <input
            type="query"
            placeholder={PLACEHOLDER_TEXT}
            className={[
              "search--input",
              "form-control",
              data?.results && data?.results.length > 0
                ? "search--input-with-data"
                : "",
            ].join(" ")}
            value={query}
            onChange={(event) => {
              const value = event.target.value;
              setQuery(value);
              onSearchDebounced.callback();

              if (value.length < MINIMUM_QUERY_LENGTH) {
                setData([]);
              }
            }}
            onKeyDown={onKeyPress}
            onKeyUp={onKeyPress}
          />
        </div>
      </form>
      {data && data?.results && data?.results.length > 0 && (
        <div className="search--results-outer-container">
          <div className="search--results-container">
            {data.results.map((result, index) => (
              <Result
                key={index + result}
                result={{ ...result }}
                selected={selectedIndex === index}
              />
            ))}
            <div className="search--results-total">
              Total results: {data.total}
              {" - "}
              <a href={pathForStaticSearch()}>View All</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {};
Search.defaultProps = {};

export default Search;
