import React from "react";
import camelcase from "camelcase";
import * as ResultTypes from "./ResultTypes";

const Result = ({ result, selected }) => {
  const props = {
    [camelcase(result.type)]: result,
  };

  const ResultComponent = ResultTypes[`${result.type}Result`];
  return (
    <div
      className={[
        "search--result",
        selected ? "search--selected" : "search--unselected",
      ].join(" ")}
    >
      <ResultComponent {...props} />
    </div>
  );
};

export default Result;
