import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.initializeSortLogic()
  }

  initializeSortLogic(){
    if ($("#steps-sortable").length > 0) {
      $("#steps-sortable").sortable({
        axis: "y",
        items: ".item",
        cursor: "move",
        handle: ".fa-grip-vertical",
        sort: function (e, ui) {
          return ui.item.addClass("active-item-shadow");
        },
        stop: function (e, ui) {
          ui.item.removeClass("active-item-shadow");
          return ui.item.children("td").effect("highlight", {}, 1000);
        },
        update: function (e, ui) {
          $("#steps-sortable").find(".step-card").each( index => {
              var step_card = $($(this).find(".step-card")[index])
              var step_card_id = `step_card_${index}`
  
              // change id of card to new index:
              step_card.attr("id", step_card_id)
              step_card = $(`#${step_card_id}`)
              
              // change id of various inputs on card to new index: 
              step_card.find('[name*="step_label"]').attr("name", `step_label_${index}`)
              step_card.find('[name*="step_type"]').attr("name", `step_type_${index}`)
              step_card.find('[name*="step_requirements"]').attr("name", `step_requirements_${index}`)
              step_card.find('[name*="instructions"]').attr("name", `instructions_${index}`)
              step_card.find('.remove-step-link').attr("target", index)
          })
        },
      });
    }
  }

  updatePrepStepTurboFrame(event) {
    var data = {}
    var stepData = [];

    $(event.target).parents("#steps-sortable").find('.step-card').each((index,step) => {
      var current_step = {}
      current_step["index"] = index
      current_step["step_label"] = $(step).find(`input[name='step_label_${index}']`)[0].value
      current_step["instructions"] = $(step).find(`input[name='instructions_${index}']`)[0].value
      current_step["step_type"] = "shipment"

      stepData.push(current_step);
    });

    data['stepData'] = stepData
    data['action'] = $(event.target)[0].getAttribute("action")
    data['target'] = $(event.target)[0].getAttribute("target")

    $('#sample_prep_step_data_for_turbo')[0].value = JSON.stringify(data)
    $("#sample-prep-step-turbo-form")[0].requestSubmit();
  }

}
