import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    document.addEventListener("turbo:frame-render", function (e) {$('.selectpicker').selectpicker('refresh');});
    this.initializeSortLogic()
    this.initializeStorageAreaFieldLogic()
    this.initializeStepDrawer()
  }
  
  initializeSortLogic(){
    if ($("#steps-sortable").length > 0) {
      $("#steps-sortable").sortable({
        axis: "y",
        items: ".item",
        cursor: "move",
        handle: ".fa-grip-vertical",
        sort: function (e, ui) {
          return ui.item.addClass("active-item-shadow");
        },
        stop: function (e, ui) {
          ui.item.removeClass("active-item-shadow");
          return ui.item.children("td").effect("highlight", {}, 1000);
        },
        update: function (e, ui) {
          $("#steps-sortable").find(".step-card").each( index => {
              var step_card = $($(this).find(".step-card")[index])
              var step_card_id = `step_card_${index}`
  
              // change id of card to new index:
              step_card.attr("id", step_card_id)
              step_card = $(`#${step_card_id}`)
              
              // change id of various inputs on card to new index: 
              step_card.find('[name*="step_label"]').attr("name", `step_label_${index}`)
              step_card.find('[name*="step_type"]').attr("name", `step_type_${index}`)
              step_card.find('[name*="step_requirements"]').attr("name", `step_requirements_${index}`)
              step_card.find('[name*="instructions"]').attr("name", `instructions_${index}`)
              step_card.find('.remove-step-link').attr("target", index)
          })
        },
      });
    }
  }

  initializeStorageAreaFieldLogic(){
    // autocomplete for storage areas
    var storage_area_field = $("#storage_area_field");
    var config_id = $("#config_id").attr('data-config-id');

    var options = {
      placeholder: "Find or Create Storage Area",
      adjustWidth: false,
      getValue: "storage_area",
      url: function (phrase) {
        return "/configuration/fetch_configuration_storage_areas?si=" + phrase + "&config_id=" + config_id;
      },
      listLocation: "storage_areas",
      list: {
        maxNumberOfElements: 10,
        match: {
          enabled: true,
        },
        sort: {
          enabled: true
        },
        onChooseEvent: function () {
          var data = {
            s: storage_area_field.val(),
            config_id: config_id
          };
          $.ajax({
            type: "GET",
            url: "/configuration/fetch_storage_area",
            data: data,
            dataType: "script",
          });
        },
        showAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
        hideAnimation: {
          type: "slide",
          time: 150,
          callback: function () {},
        },
      },
      theme: "bootstrap",
      requestDelay: 500,
    };

    storage_area_field.easyAutocomplete(options);
  }
  
  initializeStepDrawer(){
    var chosen_container_type = $("#draw_container_type").val()
    var short_type = chosen_container_type.split('/')[0]
    var visible = $('.step-drawer-options').not('.d-none')
    if (visible[0].id.split('_')[0] != short_type){
        visible.addClass('d-none')
        $(`.step-drawer-options#${short_type}_step_options`).removeClass('d-none')
    }
  }

  toggleStepDrawer(e){
    $(".step-option-drawer").toggleClass("d-none");
    $(e.target).text($(e.target).text() == 'Hide' ? 'Add another step' : 'Hide');
  }

  updateProcedureStepTurboFrame(event) {
    var data = {}
    var stepData = [];
    var modal_context = $(event.target)[0].getAttribute("modal-context")

    $(event.target).parents("#steps-sortable").find('.step-card').each((index,step) => {
      var current_step = {}
      current_step["index"] = index
      current_step["step_label"] = $(step).find(`input[name='step_label_${index}']`)[0].value
      current_step["instructions"] = $(step).find(`input[name='instructions_${index}']`)[0].value
      current_step["step_type"] = $(step).find(`input[name='step_type_${index}']`)[0].value

      if(current_step["step_type"] == "aliquot"){
        current_step["total_aliquots"] = $(step).find("#total_aliquots")[0].value
        current_step["primary_aliquots"] = $(step).find("#primary_aliquots")[0].value
        current_step["use_collection_container"] = $(step).parents("#aliquot_plan_modal").find("#use_collection_container").prop("checked")
      } else if (current_step["step_type"] == "storage"){
        current_step["storage_area_name"] = $(step).find("#storage_area_field")[0].value
        current_step["storage_duration"] = $(step).find("#storage_duration")[0].value
        current_step["storage_duration_unit"] = $(step).find("#storage_duration_unit")[0].value
        current_step["storage_temperature"] = $(step).find("#storage_temperature")[0].value
      } else {
        if (modal_context != "handling") {
          current_step["step_requirements"] = $(step).find(`select[name='step_requirements_${index}']`)[0].value
        }
      }
      stepData.push(current_step);
    });

    data['stepData'] = stepData
    data['config_id'] = $(event.target)[0].getAttribute("config-id")
    data['collection_id'] = $(event.target)[0].getAttribute("collection-id")
    data['aliquot_plan_id'] = $(event.target)[0].getAttribute("aliquot-plan-id")
    data['action'] = $(event.target)[0].getAttribute("action")
    data['target'] = $(event.target)[0].getAttribute("target")
    data['default_step'] = $(event.target)[0].getAttribute("default-step")
    data['new_step_type'] = $(event.target)[0].getAttribute("new-step-type")
    data['modal_context'] = modal_context

    if (modal_context == "collection") {
      data['draw_container_type'] = $(event.target).closest("#collection_step_modal").find(`#draw_container_type`).val()
    }

    $(event.target).closest(".modal").find('#procedure_step_data_for_turbo')[0].value = JSON.stringify(data)
    $(event.target).closest(".modal").find("#procedure-step-turbo-form")[0].requestSubmit();
  }
}
