import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="mobile--item-select"
export default class extends Controller {
  static targets = [
    "noSelectionText",
    "selectionText",
    "selectionCount",
    "button",
    "actionItem",
    "actionLink",
    "noActions",
    "itemRow",
  ];

  static values = {
    actionPath: String,
    context: String,
  };

  connect() {
    this.selectedItems = new Map();
    this.updateText();

    this.recentlyScrolledElems = [];
    this.recentlyScrolledTimeout = 0;

    this.actionLinkTargets.forEach((target) => {
      target.dataset.url = target.href;
    });
  }

  selected(ev) {
    const id = ev.params["id"];
    const events = ev.params["events"];

    /** @type {HTMLInputElement} */
    const checkbox = ev.target;
    const isSelected = checkbox.checked;

    if (isSelected) {
      this.selectedItems.set(id, events);
    } else {
      this.selectedItems.delete(id);
    }

    this.updateText();
    this.updateActions();
  }

  /**
   * When an item row is connected, inform user.
   *
   * Elements are only scrolled to when they have the data-scroll
   * attribute, and it only scrolls once per interval.
   *
   * @param {HTMLElement} elem
   */
  itemRowTargetConnected(elem) {
    if (!elem.hasAttribute("data-scroll")) return;

    this.recentlyScrolledElems.push(elem);
    elem.classList.add("m-scroll");

    // Wait for DOM to settle and collect the top-most element.
    if (this.recentlyScrolledTimeout)
      clearTimeout(this.recentlyScrolledTimeout);
    this.recentlyScrolledTimeout = setTimeout(() => {
      const topElement = this.recentlyScrolledElems.reduce((prev, current) =>
        prev.y > current.y ? prev : current
      );
      topElement.scrollIntoView({ behavior: "smooth" });

      this.recentlyScrolledElems = [];
    }, 10);

    elem.removeAttribute("data-scroll");
  }

  /**
   * When an item row is removed, make sure the ID is removed from the
   * internal selections list.
   *
   * @param {HTMLElement} elem
   */
  itemRowTargetDisconnected(elem) {
    const id = parseInt(elem.dataset.id, 10);
    this.deselectItems([id]);
  }

  action({ params }) {
    const action = params["action"];
    const selectedItemKeys = [...this.selectedItems.keys()];

    const formData = new FormData();
    formData.set("context", this.contextValue);
    formData.set("transition_action", action);
    selectedItemKeys.forEach((item_id) => {
      formData.append("item_ids[]", item_id);
    });

    const req = new FetchRequest("get", this.actionPathValue, {
      query: formData,
      responseKind: "turbo-stream",
    });

    req.perform().then(() => {
      this.deselectItems(selectedItemKeys);
    });
  }

  /**
   * Deselect all provided items.
   * @param {Array} items
   */
  deselectItems(items) {
    items.forEach((key) => this.selectedItems.delete(key));

    this.updateText();
    this.updateActions();
  }

  /**
   * Update displayed text based on the current selection state.
   */
  updateText() {
    const selectionCount = this.selectedItems.size;
    const hasSelection = selectionCount > 0;

    this.noSelectionTextTarget.classList.toggle("d-none", hasSelection);
    this.selectionTextTarget.classList.toggle("d-none", !hasSelection);

    this.buttonTarget.disabled = !hasSelection;

    const count = document.createTextNode(selectionCount);
    this.selectionCountTarget.replaceChildren(count);
  }

  /**
   * Calculate actions that can be applied to every selected item.
   * @returns {Set}
   */
  enabledActions() {
    const selectionCount = this.selectedItems.size;
    const countedActions = new Map();

    this.selectedItems.forEach((events) => {
      events.forEach((event) => {
        const count = countedActions.get(event) || 0;
        countedActions.set(event, count + 1);
      });
    });

    const actions = [...countedActions]
      .filter(([action, count]) => count === selectionCount)
      .map(([action, count]) => action);
    return new Set(actions);
  }

  /**
   * Enable and disable actions in menu depending on selected items.
   */
  updateActions() {
    const enabledActions = this.enabledActions();
    var hasEnabledActions = false;

    this.actionItemTargets.forEach((target) => {
      const targetAction = target.dataset.actionItem;
      const enabled = enabledActions.has(targetAction);
      target.classList.toggle("d-none", !enabled);
      hasEnabledActions |= enabled;
    });

    this.noActionsTarget.classList.toggle("d-none", hasEnabledActions);

    this.actionLinkTargets.forEach((target) => {
      const url = new URL(target.dataset.url, window.location.origin);

      [...this.selectedItems.keys()].forEach((key) => {
        url.searchParams.append("scan_ids[]", key);
      });

      target.href = url;
    });
  }
}

// Reset item checkboxes before caching.
document.addEventListener("turbo:before-cache", () => {
  [...document.querySelectorAll(".reset-checkbox")].forEach((checkbox) => {
    checkbox.checked = false;
  });

  [...document.querySelectorAll("[data-scroll]")].forEach((elem) => {
    elem.removeAttribute("data-scroll");
  });
});
