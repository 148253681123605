import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

import { HTMLCanvasElementLuminanceSource } from "@zxing/browser";
import {
  BrowserMultiFormatReader,
  BinaryBitmap,
  HybridBinarizer,
  NotFoundException,
} from "@zxing/library";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class BrowserScanner extends EventTarget {
  static supported = true;

  constructor(elem) {
    super();

    this.reader = new BrowserMultiFormatReader();
    this.cancel = new AbortController();

    this.elem = elem;
  }

  async start() {
    let sleepDuration = 100;
    this.cancel = new AbortController();

    while (true) {
      if (this.cancel.signal.aborted) {
        break;
      }

      let result;
      try {
        const luminanceSource = new HTMLCanvasElementLuminanceSource(this.elem);
        const hybridBinarizer = new HybridBinarizer(luminanceSource);
        const bitmap = new BinaryBitmap(hybridBinarizer);
        result = this.reader.decodeBitmap(bitmap);
      } catch (err) {
        if (!(err instanceof NotFoundException)) {
          console.error(err);
          alert(err);
        }
      }

      if (result) {
        let cornerPoints = null;
        const points = result.getResultPoints();

        if (points.length === 4) {
          cornerPoints = points.map((point) => {
            return { x: point.getX(), y: point.getY() };
          });
        }

        const formattedResult = {
          value: result.getText(),
          cornerPoints,
        };

        this.dispatchEvent(
          new CustomEvent("scan", {
            detail: {
              results: [formattedResult],
            },
          })
        );
      }

      await sleep(sleepDuration);
    }
  }

  stop() {
    this.reader.reset();
    this.cancel.abort();
  }
}

class NativeScanner extends EventTarget {
  static supported = "BarcodeDetector" in window;

  constructor(elem) {
    super();

    this.detector = new BarcodeDetector();
    this.cancel = new AbortController();

    this.elem = elem;
  }

  static async getSupportedFormats() {
    return await BarcodeDetector.getSupportedFormats();
  }

  async start() {
    let sleepDuration = 200;
    this.cancel = new AbortController();

    while (true) {
      if (this.cancel.signal.aborted) {
        break;
      }

      const results = await this.detector.detect(this.elem);

      if (results.length > 0) {
        const formattedResults = results.map((result) => {
          return {
            value: result.rawValue,
            cornerPoints: result.cornerPoints,
          };
        });

        this.dispatchEvent(
          new CustomEvent("scan", {
            detail: {
              results: formattedResults,
            },
          })
        );
      }

      await sleep(sleepDuration);
    }
  }

  stop() {
    this.cancel.abort();
  }
}

/**
 * An interface for getting scan events from keyboard-like barcode scanners.
 */
class HardwareScanner extends EventTarget {
  static #timeout = 20;

  #timeoutHandler = 0;
  #inputString = "";

  constructor() {
    super();

    document.addEventListener("keyup", (ev) => this.#keyup(ev));

    if (this.#timeoutHandler) {
      clearTimeout(this.#timeoutHandler);
    }

    this.#timeoutHandler = setTimeout(() => {
      this.#inputString = "";
    }, HardwareScanner.#timeout);
  }

  /**
   * Remove event listeners.
   */
  close() {
    document.removeEventListener("keyup", this.#keyup);
  }

  /**
   * Handle a keyup on document.
   * @param {KeyboardEvent} ev
   */
  #keyup(ev) {
    if (ev.key?.length !== 1) {
      return;
    }

    if (this.#timeoutHandler) {
      clearTimeout(this.#timeoutHandler);
      this.#inputString += ev.key;
    }

    this.#timeoutHandler = setTimeout(() => {
      if (this.#inputString.length > 3) {
        this.dispatchEvent(
          new CustomEvent("scan", {
            detail: {
              results: [
                {
                  value: this.#inputString,
                  cornerPoints: [],
                  hardware: true,
                },
              ],
            },
          })
        );
      }

      this.#inputString = "";
    }, HardwareScanner.#timeout);
  }
}

export default class CameraBarcodeController extends Controller {
  static targets = [
    "scannerBox",
    "preview",
    "video",
    "hardwareBox",
    "value",
    "startButton",
    "stopButton",
    "modeSetting",
    "deviceSetting",
    "audioSetting",
  ];

  static values = {
    id: String,
    single: Boolean,
    redirect: String,
    stream: String,
    delay: { type: Number, default: 500 },
    autostart: { type: Boolean, default: false },
    disableNative: { type: Boolean, default: false },
  };

  /** @type {MediaDeviceInfo[]?} */
  static devices = null;

  initialize() {
    if (!this.idValue) {
      this.idValue = window.crypto?.randomUUID();
    }
  }

  async connect() {
    if (NativeScanner.supported && !this.disableNativeValue) {
      console.debug("Using native BarcodeDetector API");
      this.scanner = new NativeScanner(this.previewTarget);
    } else {
      console.debug("Using JS barcode library");
      this.scanner = new BrowserScanner(this.previewTarget);
    }

    this.hardwareScanner = new HardwareScanner();

    this.lastScannedCode = "";
    this.clearScanTimeout = 0;
    this.cleanup();
    this.updateButton();

    document.addEventListener(
      "scanning-started",
      this.activeScanner.bind(this)
    );

    /** @type {CanvasRenderingContext2D} */
    this.ctx = this.previewTarget.getContext("2d");

    this.scanner.addEventListener("scan", (ev) => {
      if (this.isFrozen) return;

      this.handleScan(ev.detail.results);
    });

    this.hardwareScanner.addEventListener("scan", (ev) => {
      if (this.isScanning) {
        this.handleScan(ev.detail.results);
      }
    });

    this.updateSettings();

    this.modeSettingTarget.value = this.scannerSourcePreference();
    this.audioSettingTarget.checked = this.audioFeedbackEnabled();

    if (this.scannerSourcePreference() === "hardware") {
      this.deviceSettingTarget.parentNode.classList.add("d-none");
    }

    if (this.autostartValue) {
      await this.start();
    }
  }

  disconnect() {
    this.cleanup();

    document.removeEventListener(
      "scanning-started",
      this.activeScanner.bind(this)
    );
  }

  cleanup() {
    if (this.hasScannerBoxTarget) this.scannerBoxTarget.classList.add("d-none");
    this.device?.getTracks?.().forEach?.((track) => track.stop());

    this.isScanning = false;
    this.isFrozen = false;
    this.hasStartedResolve = null;
    this.device = null;
    this.deviceId = null;

    this.scanner?.stop();
    this.updateButton();

    this.hardwareScanner?.close();
    if (this.hasHardwareBoxTarget)
      this.hardwareBoxTarget.classList.add("d-none");

    if (this.clearScanTimeout) clearTimeout(this.clearScanTimeout);
  }

  updateButton() {
    if (!this.hasStartButtonTarget || !this.hasStopButtonTarget) {
      return;
    }

    this.startButtonTarget.classList.toggle("d-none", this.isScanning);
    this.stopButtonTarget.classList.toggle("d-none", !this.isScanning);
  }

  /**
   * Attempt to get the best video stream, using stored preferences.
   * @returns {Promise<MediaStream?>}
   */
  async getBestMediaDevice() {
    // We only need to check permissions if we don't already have devices.
    let needsPermission = CameraBarcodeController.devices === null;
    this.deviceId = null;

    // See if we already have permission.
    if (needsPermission) {
      try {
        const query = await navigator.permissions.query({ name: "camera" });
        needsPermission = query.state !== "granted";
      } catch (err) {
        console.error("Could not check permissions", err);
      }
    }

    // If we need permission, device logic is a little different to try to
    // avoid opening the camera multiple times.
    if (needsPermission) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        });

        // If we only have one device, reuse it.
        const devices = await navigator.mediaDevices.enumerateDevices();
        CameraBarcodeController.devices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (CameraBarcodeController.devices.length === 1) {
          return mediaStream;
        }

        // Ultra wide allows for much closer focusing.
        const preferredDeviceId = CameraBarcodeController.devices.find(
          (device) => device.label === "Back Ultra Wide Camera"
        )?.deviceId;

        // If we don't have a preference or preferred, use the provided device.
        const videoDevicePreference = this.videoDevicePreference();
        if (!videoDevicePreference && !preferredDeviceId) {
          return mediaStream;
        }

        // Otherwise, stop all tracks so we can attempt loading preferences.
        mediaStream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        console.error("Couldn't load devices for needs permission check", err);
      }
    // If we don't need permissions, but devices is null, get device list.
    } else if (!CameraBarcodeController.devices) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      CameraBarcodeController.devices = devices.filter(
        (device) => device.kind === "videoinput"
      );
    }

    // No cameras available, nothing we can do.
    if (!CameraBarcodeController.devices) {
      return null;
    }

    // Start by enumerating devices. If there's only one video track available,
    // we don't need to worry about preferences.
    try {
      if (CameraBarcodeController.devices.length === 1) {
        return await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        });
      }
    } catch {
      // If we can't use the one device, nothing will succeed afterwards.
      return null;
    }

    // Attempt to load from preferences.
    try {
      const preferredDeviceId = CameraBarcodeController.devices.find(
        (device) => device.label === "Back Ultra Wide Camera"
      )?.deviceId;

      const deviceId = this.videoDevicePreference() || preferredDeviceId;
      if (deviceId) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            deviceId,
          },
        });

        this.deviceId = deviceId;

        return mediaStream;
      }
    } catch (err) {
      console.error("Could not load camera from preferences", err);
    }

    // If no preferences, or something failed before, try loading default.
    try {
      return await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
        },
      });
    } catch {
      return null;
    }
  }

  updateSettings() {
    const cameraOptions = CameraBarcodeController.devices?.map((device) => {
      const option = document.createElement("option");
      option.value = device.deviceId;
      option.appendChild(document.createTextNode(device.label));
      return option;
    });

    if (cameraOptions) {
      this.deviceSettingTarget.replaceChildren(...cameraOptions);
      this.deviceSettingTarget.disabled = false;
    }

    if (this.deviceId) {
      this.deviceSettingTarget.value = this.deviceId;
    }
  }

  /**
   * Set the user's preferred source for scanning.
   * @param {"camera" | "hardware"} source
   */
  async setScannerSourcePreference(source) {
    localStorage.setItem("scanner-source", source);

    this.deviceSettingTarget.parentNode.classList.toggle(
      "d-none",
      source === "hardware"
    );

    this.cleanup();
    await this.start();
  }

  /**
   * Check if the user has a preference for camera or hardware scanner.
   * Returns camera if no preference was set.
   * @returns {"camera" | "hardware"}
   */
  scannerSourcePreference() {
    const pref = localStorage.getItem("scanner-source");
    return pref || "camera";
  }

  videoDevicePreference() {
    return localStorage.getItem("scanner-camera-device-id");
  }

  /**
   * Set a new video device preference.
   *
   * This will also update the existing camera feed.
   * @param {String} deviceId
   */
  async setVideoDevicePreference(deviceId) {
    const device = CameraBarcodeController.devices.find(
      (device) => device.deviceId == deviceId
    );
    if (!device) {
      throw new Error(
        `Can't set device preference to unknown device: ${deviceId}`
      );
    }

    localStorage.setItem("scanner-camera-device-id", deviceId);

    // Kind of a hack, but it allows for reusing existing camera setup logic.
    this.cleanup();
    await this.start();
  }

  /**
   * Check if audio feedback is enabled.
   * @returns {Boolean}
   */
  audioFeedbackEnabled() {
    return (
      (localStorage.getItem("scanner-audio-feedback") || "true") === "true"
    );
  }

  /**
   * Set if audio feedback should be enabled.
   * @param {Boolean} enabled
   */
  setAudioFeedback(enabled) {
    localStorage.setItem("scanner-audio-feedback", enabled ? "true" : "false");
  }

  /**
   * Start or stop the barcode scanner.
   */
  async start() {
    if (this.isScanning) {
      this.cleanup();
      return;
    }

    const sourcePreference = this.scannerSourcePreference();
    let useCamera = sourcePreference === "camera";

    if (useCamera) {
      this.scannerBoxTarget.classList.remove("d-none");
      this.device = await this.getBestMediaDevice();
      if (!this.device) useCamera = false;
    }

    this.isScanning = true;

    this.updateSettings();

    if (useCamera) {
      this.isFrozen = false;

      const hasStarted = new Promise((resolve) => {
        this.hasStartedResolve = resolve;
      });

      this.videoTarget.srcObject = this.device;
      this.videoTarget.play();

      await hasStarted;

      this.copyToCanvas();
      this.scanner.start();

      this.modeSettingTarget.value = "camera";
    } else if (this.hasHardwareBoxTarget) {
      this.scannerBoxTarget.classList.add("d-none");
      this.hardwareBoxTarget.classList.remove("d-none");

      this.modeSettingTarget.value = "hardware";
    }

    document.dispatchEvent(
      new CustomEvent("scanning-started", {
        detail: {
          id: this.idValue,
        },
      })
    );

    this.updateButton();
  }

  /**
   * Keep updating canvas preview of video feed. Will stop when no longer
   * scanning and pauses updates while frozen.
   */
  copyToCanvas() {
    if (!this.isScanning) {
      return;
    }

    if (!this.isFrozen) {
      this.ctx.drawImage(
        this.videoTarget,
        0,
        0,
        this.videoTarget.videoWidth,
        this.videoTarget.videoHeight
      );
    }

    requestAnimationFrame(this.copyToCanvas.bind(this));
  }

  /**
   * Handle a scan result.
   * @param {Array} results
   */
  handleScan(results) {
    // Only handle first result, but make sure it exists
    if (results.length === 0) {
      return;
    }
    const result = results[0];

    if (result.value === this.lastScannedCode) {
      return;
    }

    if (this.clearScanTimeout) clearTimeout(this.clearScanTimeout);
    this.clearScanTimeout = setTimeout(
      () => (this.lastScannedCode = ""),
      3 * 1e3
    );

    this.lastScannedCode = result.value;

    this.dispatch("scan", { detail: { text: result.value } });

    if (!result["hardware"]) {
      this.scanFeedback();
    }

    if (this.hasValueTarget) {
      this.valueTarget.value = result.value;
    }

    if (
      this.singleValue === true ||
      this.hasRedirectValue ||
      this.hasStreamValue
    ) {
      this.isFrozen = true;
      this.drawBoundingBox(result);

      setTimeout(() => {
        if (this.singleValue === true) {
          this.cleanup();
        }

        if (this.hasRedirectValue) {
          const url = new URL(this.redirectValue, window.location.origin);
          url.searchParams.append("value", result.value);

          Turbo.visit(url);
        }

        if (this.hasStreamValue) {
          const req = new FetchRequest("post", this.streamValue, {
            body: { value: result.value },
            responseKind: "turbo-stream",
          });

          req.perform().then((resp) => {
            const feedback = resp.response.headers.get("x-scanner-feedback");
            if (feedback) {
              this.scanFeedback(true, true, feedback);
            }
            this.isFrozen = false;
          });
        }
      }, this.delayValue);
    }
  }

  /**
   * Provide feedback to the user that a scan was successful.
   */
  scanFeedback(audio = true, vibrate = true, intensity = "normal") {
    if (!this.audioFeedbackEnabled()) {
      return;
    }

    if (vibrate && "vibrate" in window.navigator) {
      const length = intensity === "normal" ? 150 : 400;
      window.navigator.vibrate(length);
    }

    if (audio) {
      const audioCtx = new AudioContext();

      const oscillator = audioCtx.createOscillator();
      const gainNode = audioCtx.createGain();

      oscillator.connect(gainNode);
      gainNode.connect(audioCtx.destination);

      if (intensity === "strong") {
        gainNode.gain.value = 0.15;
        oscillator.frequency.value = 654;
        oscillator.type = "sawtooth";

        oscillator.start(0);
        oscillator.stop(0.35);

        gainNode.gain.setValueAtTime(0, audioCtx.currentTime + 0.1);
        gainNode.gain.setValueAtTime(0.15, audioCtx.currentTime + 0.25);
      } else {
        gainNode.gain.value = 0.25;

        oscillator.frequency.value = 495;
        oscillator.type = "triangle";

        oscillator.start(0);
        oscillator.stop(0.15);
      }
    }
  }

  playing(ev) {
    ev.target.scrollIntoView({ block: "end" });

    // Update canvas dimensions to cropped video dimensions
    // Width is always the same as we want to fill horizontally
    this.ctx.canvas.width = this.videoTarget.videoWidth;

    // Calculate height based on aspect ratio of parent container
    const parent = this.previewTarget.parentNode;
    this.ctx.canvas.height =
      this.ctx.canvas.width * (parent.clientHeight / parent.clientWidth);

    if (this.hasStartedResolve) {
      this.hasStartedResolve();
    }
  }

  /**
   * Draw a bounding box around a result on result canvas.
   * @param {object} result
   */
  drawBoundingBox(result) {
    const points = result.cornerPoints;

    if (points?.length !== 4) {
      return;
    }

    const scaleX = 1,
      scaleY = 1;

    this.ctx.strokeStyle = "red";
    this.ctx.lineWidth = 5;

    this.ctx.beginPath();
    this.ctx.moveTo(points[0].x * scaleX, points[0].y * scaleY);
    this.ctx.lineTo(points[1].x * scaleX, points[1].y * scaleY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(points[1].x * scaleX, points[1].y * scaleY);
    this.ctx.lineTo(points[2].x * scaleX, points[2].y * scaleY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(points[2].x * scaleX, points[2].y * scaleY);
    this.ctx.lineTo(points[3].x * scaleX, points[3].y * scaleY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(points[3].x * scaleX, points[3].y * scaleY);
    this.ctx.lineTo(points[0].x * scaleX, points[0].y * scaleY);
    this.ctx.stroke();
  }

  activeScanner({ detail }) {
    if (detail["id"] === this.idValue) {
      return;
    }

    this.cleanup();
  }

  changeMode(ev) {
    const mode = ev.target.value;
    this.setScannerSourcePreference(mode);
  }

  changeDevice(ev) {
    const device = ev.target.value;
    this.setVideoDevicePreference(device);
  }

  changeAudio(ev) {
    const enabled = ev.target.checked;
    this.setAudioFeedback(enabled);
  }

  resetSettings() {
    localStorage.clear();
  }
}
