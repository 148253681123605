import { Controller } from "@hotwired/stimulus"

/**
 * Attempts to detect if the user is on an iPad.
 * @returns {Boolean}
 */
function isIPad() {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";

  return (platform === "iPad") || (platform === "MacIntel" && navigator.maxTouchPoints > 0);
}

/**
 * Set the appropriate cookie to enable or disable the mobile site.
 * @param {Boolean} isMobile If the mobile site should be presented.
 */
function setMobileCookie(isMobile) {
  console.debug("Setting mobile cookie", isMobile);
  document.cookie = `nls_mobile=${isMobile}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax; Path=/`;
}

// For debugging, expose this function.
window["setMobileCookie"] = setMobileCookie;

/**
 * Detection to ensure mobile users are presented with the mobile site.
 * 
 * Unlike phones, the iPad and iPad Pro pretend to be a Mac in their
 * user agent. We therefore need to detect their true nature in
 * JavaScript and send this data back to the server.
 * 
 * In the future this controller can be extended to add a mobile toggle
 * that does not exclusively rely on user agent.
 * 
 * Connects to data-controller="mobile--detect"
 */
export default class extends Controller {
  connect() {
    if (isIPad()) {
      console.debug("Device appears to be iPad");
      setMobileCookie(true);
    }
  }
}
