import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
    initialize() {
        this.selectedSampleIds = new Set();
    }

    static get targets() {
        return [
            "modalButton", 
            "subjectSelect", 
            "visitSelect",
            "procedureSelect",
            "timepointSelect",
            "sampleCheck",
            "kickerSubmit",
            "kickerChecker",
            "swapSamplesCheck",
            "recordConflict"
        ]
    }

    static values = {
        workspaceId: String,
        collectionId: String
    }

    connect(){
        $("#swap_toggle").tooltip()
        this.checkedSamples = new Set(this.sampleCheckTargets.filter((box) => {
            return box ? box.checked : false;
          }).map((box) => box.dataset.sampleId));
          this.handleConflicts()
    }

    toggle(event) {
        const sampleId = event.target.dataset.sampleId
        if (this.checkedSamples.has(sampleId)) {
          this.checkedSamples.delete(sampleId);
        } else {
          this.checkedSamples.add(sampleId);
        }
        this.updateConflictChecker()
    }

    updateConflictChecker(){
        if (this.checkedSamples.size > 0 && this.timepointSelectTarget.value){
            this.kickerCheckerTarget.disabled = false
            this.kickerCheckerTarget.classList.remove("disabled");
        } else {
            this.kickerCheckerTarget.disabled = true
            this.kickerCheckerTarget.classList.add("disabled");
        }
    }
    updateSubmitButton(){
        if (this.checkedSamples.size > 0 && this.timepointSelectTarget.value){
            this.kickerSubmitTarget.disabled = false
        } else {
            this.kickerSubmitTarget.disabled = true
        }
    }

    fetchContext(e){
        var subject_id = this.subjectSelectTarget.value
        var visit_id = this.visitSelectTarget.value
        var procedure_id = this.procedureSelectTarget.value
        var timepoint_id = this.timepointSelectTarget.value
        
        get(`/workspaces/${this.workspaceIdValue}/fetch_sample_kicker_options?collection_id=${this.collectionIdValue}&subject_id=${subject_id}&visit_id=${visit_id}&procedure_id=${procedure_id}&timepoint_id=${timepoint_id}&selected_samples=${Array.from(this.checkedSamples)}`, { responseKind: 'turbo-stream' })
    }

    checkForConflicts(e){
        e.preventDefault();

        var visit_id = this.visitSelectTarget.value
        var timepoint_id = this.timepointSelectTarget.value
        
        get(`/workspaces/${this.workspaceIdValue}/check_for_kicker_conflicts?collection_id=${this.collectionIdValue}&visit_id=${visit_id}&timepoint_id=${timepoint_id}&selected_samples=${Array.from(this.checkedSamples)}`, { responseKind: 'turbo-stream' })
    }

    handleConflicts(e){
        if ((this.recordConflictTargets.length == 0 || (this.recordConflictTargets.length > 0 && this.recordConflictTargets.filter((box) => {return box ? box.checked : false;}).length > 0)) && (this.swapSamplesCheckTargets.length == 0 || (this.swapSamplesCheckTargets.length > 0 && this.swapSamplesCheckTarget.checked))){
            this.kickerSubmitTarget.disabled = false
            this.kickerSubmitTarget.classList.remove("disabled");
        } else {
            this.kickerSubmitTarget.disabled = true
            this.kickerSubmitTarget.classList.add("disabled");
        }
    }
}
