import { Controller } from "@hotwired/stimulus";

/**
 * Check if an element is visible on the current page.
 * @param {HTMLElement} elem
 * @returns {Boolean}
 */
function elemIsVisible(elem) {
  const rect = elem.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * Provide animated effects to newly added elements.
 *
 * Any fade target added to the element after the page has loaded will
 * have the effect from the mode value applied to it.
 *
 * Connects to data-controller="fade"
 */
export default class extends Controller {
  static targets = ["fade"];

  static values = {
    mode: { type: String, default: "background" },
    afterAppear: { type: Boolean, default: false },
  };

  initialize() {
    this.hasConnected = false;
  }

  connect() {
    this.hasConnected = true;

    document.addEventListener("animationend", this.finished.bind(this));
  }

  disconnect() {
    document.removeEventListener("animationend", this.finished.bind(this));
  }

  /**
   * Prepare a newly added element to fade.
   * @param {HTMLElement} elem
   */
  fadeTargetConnected(elem) {
    if (!this.hasConnected) return;
    if (!this.afterAppearValue && !elemIsVisible(elem)) return;

    const mode = elem.dataset.fadeMode;

    if (this.afterAppearValue) {
      if (elemIsVisible(elem)) {
        this.addClasses(elem, mode);
        return;
      }

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.addClasses(elem, mode);
          observer.disconnect();
        }
      });

      observer.observe(elem);
    } else {
      this.addClasses(elem, mode);
    }
  }

  /**
   * Add relevant animation classes to element.
   * @param {HTMLElement} elem
   * @param {String} mode Override mode for element
   */
  addClasses(elem, mode = null) {
    elem.style.setProperty("--content-height", `${elem.offsetHeight}px`);
    elem.classList.add("controller-fade", `fade-${mode || this.modeValue}`);
  }

  /**
   * Clean up element after animation finishes.
   * @param {Event} ev
   */
  finished(ev) {
    ev.target.classList.remove("controller-fade");
  }
}

// Remove fade from elements before caching.
document.addEventListener("turbo:before-cache", () => {
  [...document.querySelectorAll(".controller-fade")].forEach((elem) => {
    elem.classList.remove("controller-fade");
  });
});
