import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="form--step-autofill"
export default class extends Controller {
  static targets = ["depends"];

  static values = {
    stream: String,
  };

  connect() {
    this.values = new Map();
    this.lastValue = "";

    this.dependsTargets.forEach((depends) => (depends.disabled = true));
  }

  update({ target, params, detail }) {
    // Only update when value changes.
    if (this.lastValue === target.value) {
      return;
    }

    const name = params["name"];
    this.values.set(name, target.value);
    this.lastValue = target.value;

    // Reset each dependent field.
    this.dependsTargets.forEach((depends) => {
      if (depends.dataset.dependsOn === name) {
        depends.value = "";
        depends.disabled = true;
      }
    });

    // If it's not valid, no point in fetching new entries.
    if (detail && detail["isValid"] !== undefined && !detail["isValid"]) {
      return;
    }

    const formData = new FormData();
    for (const [name, value] of this.values) {
      formData.set(name, value);
    }

    const req = new FetchRequest("get", this.streamValue, {
      query: formData,
      responseKind: "turbo-stream",
    });

    // Re-enable fields now that results are loaded.
    req.perform().then(() => {
      this.dependsTargets.forEach((depends) => {
        if (depends.dataset.dependsOn === name) {
          depends.disabled = false;
        }
      });
    });
  }
}
