import { Controller } from "@hotwired/stimulus";

/**
 * Create a template for the inputSpinner with the correct actions and targets.
 * @param {string} target A target for this controller to find the input element.
 * @returns {string}
 */
function createSpinnerTemplate(target) {
  return `<div class="input-group \${groupClass}">
    <input type="number" inputmode="decimal" style="text-align: \${textAlign}; border-width: 1px 0px 1px 1px;" class="form-control form-control-text-input" bootstrap-input-spinner="true" data-action="change->protocols--enrollment-target#updateCounts" data-protocols--enrollment-target-target="${target}">
    <button style="min-width: \${buttonsWidth}; border-radius: 0px 0px 0px 0px;" class="btn btn-decrement \${buttonsClass} btn-minus" type="button">\${decrementButton}</button>
    <button style="min-width: \${buttonsWidth}; border-radius: 0px 5px 5px 0px; border-width: 1px 1px 1px 0px;" class="btn btn-increment \${buttonsClass} btn-plus" type="button">\${incrementButton}</button>
  </div>`;
}

// Connects to data-controller="protocols--enrollment-target"
export default class extends Controller {
  static targets = [
    "cohortControl",
    "cohortCount",
    "cohortsCheckbox",
    "save",
    "subjectCounts",
    "totalCount",
  ];

  initialize() {
    this.numberFormatter = new Intl.NumberFormat();

    this.totalCount = 0;
    this.cohortCount = 0;

    this.cohortsVisible = false;
  }

  connect() {
    this.cohortsVisible = this.hasCohortsCheckboxTarget
      ? this.cohortsCheckboxTarget.checked
      : false;

    this.updateCohortControlVisibility();
    this.updateCounts();

    $(this.totalCountTarget).inputSpinner({
      template: createSpinnerTemplate("totalCount"),
    });
    $(this.cohortCountTargets).inputSpinner({
      template: createSpinnerTemplate("cohortCount"),
    });
  }

  /**
   * Recalculate and display the entered counts when an input is updated.
   */
  updateCounts() {
    this.calculateCounts();
    this.updateCohortCount();
  }

  /**
   * Toggle if cohort related features are enabled.
   * @param {InputEvent} ev
   */
  toggleCohorts(ev) {
    this.cohortsVisible = ev.target.checked;
    this.updateCohortControlVisibility();
  }

  /**
   * Show or hide the cohort controls depending on if they are enabled.
   */
  updateCohortControlVisibility() {
    this.cohortControlTargets.forEach((target) => {
      target.classList.toggle("d-none", !this.cohortsVisible);
    });

    this.updateCohortCount();
  }

  /**
   * Updates the total and cohort counts.
   */
  calculateCounts() {
    this.totalCount = parseInt(this.totalCountTarget.value, 10) || 0;
    this.cohortCount = this.cohortCountTargets.reduce((count, target) => {
      return count + (parseInt(target.value, 10) || 0);
    }, 0);
  }

  /**
   * Update the text to show the distributed subject counts and control if the
   * save button is enabled or not.
   */
  updateCohortCount() {
    const mismatchedDistribution = this.cohortCount !== this.totalCount;

    this.saveTarget.disabled = mismatchedDistribution && this.cohortsVisible;

    if (this.cohortsVisible) {
      const target = this.subjectCountsTarget;
      target.classList.toggle("text-danger", mismatchedDistribution);

      const total = this.numberFormatter.format(this.totalCount);
      const allocated = this.numberFormatter.format(this.cohortCount);

      const text = document.createTextNode(`${allocated}/${total} distributed`);
      target.replaceChildren(text);
    }
  }
}
