import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table-collapse"
export default class extends Controller {
  static targets = ["row"];

  connect() {
    this.colSpan = this.calculateColSpan();

    let lastWasEmpty = false;
    let marker = null;
    let collapsedRows = 1;

    /** @type {Array<HTMLTableRowElement>} */
    const rows = this.rowTargets;
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i];
      const rowIsEmpty = row.dataset.empty === "true";

      collapsedRows += 1;

      if (rowIsEmpty && lastWasEmpty) {
        row.classList.add("d-none", "row-collapsed");

        if (!marker) {
          const previousRow = rows[i - 1];
          previousRow.classList.add("d-none", "row-collapsed");

          marker = document.createElement("tr");
          marker.dataset.action = "click->table-collapse#expand";
          marker.classList.add("row-collapse-marker");

          previousRow.insertAdjacentElement("beforebegin", marker);
        }

        marker.replaceChildren(this.buildMarkerContents(collapsedRows));
      } else {
        marker = null;
        collapsedRows = 1;
      }

      lastWasEmpty = rowIsEmpty;
    }
  }

  /**
   * Calculate the colspan required to span the width of the entire table.
   * This can be more than the number of columns if some are set with a colspan.
   *
   * @returns {Number}
   */
  calculateColSpan() {
    return Array.from(this.rowTargets[0].querySelectorAll("td, th")).reduce(
      (acc, col) => {
        const colSpan = parseInt(col.getAttribute("colspan"), 10) || 1;
        return acc + colSpan;
      },
      0
    );
  }

  /**
   * Build the contents of the marker element.
   *
   * @param {Number} rowCount Number of rows
   * @returns {Text}
   */
  buildMarkerContents(rowCount) {
    const column = document.createElement("td");
    column.classList.add("cursor-pointer");
    column.colSpan = this.colSpan;

    const container = document.createElement("span");
    const message = document.createTextNode(`Expand ${rowCount} empty rows`);
    container.appendChild(message);
    column.appendChild(container);

    const indicator = document.createElement("i");
    indicator.classList.add("fa-solid", "fa-chevron-down", "pl-2");
    column.appendChild(indicator);

    return column;
  }

  /**
   * User requested expansion of rows.
   *
   * @param {MouseEvent} ev
   */
  expand(ev) {
    /** @type {HTMLElement | null} */
    const target = ev.target;
    if (!target) return;

    const tableRow = target.tagName === "TR" ? target : target.closest("tr");

    let previousRow = tableRow;
    while (true) {
      const row = previousRow?.nextElementSibling;
      if (!row?.classList?.contains("row-collapsed")) {
        break;
      }

      row.classList.remove("row-collapsed", "d-none");

      previousRow = row;
    }

    tableRow.remove();
  }
}
