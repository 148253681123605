import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

/**
 * Automatically reload page with new select filter values.
 * 
 * Connects to data-controller="mobile--filter"
 */
export default class extends Controller {
  /**
   * Run when select value changes.
   * @param {InputEvent} ev
   */
  changed(ev) {
    /** @type {HTMLSelectElement} */
    const select = ev.target;
    const fieldName = select.name;
    const selectedValue = select.options[select.selectedIndex].value;

    const url = new URL(window.location.href);
    url.searchParams.delete("page"); // Reset pagination on filter change

    if (selectedValue === "") {
      url.searchParams.delete(fieldName);
    } else {
      url.searchParams.set(fieldName, selectedValue);
    }

    Turbo.visit(url);
  }
}
