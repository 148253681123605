import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="reorder-table"
export default class extends Controller {
  static values = {
    url: String
  };

  static targets = ["body"]

  connect() {
    this.row = null;
  }

  dragstart(ev) {
    this.row = ev.target;
  }

  dragover(ev) {
    ev.preventDefault();

    let children = [...ev.target.parentNode.parentNode.children];

    if (children.indexOf(ev.target.parentNode) > children.indexOf(this.row)) {
      ev.target.parentNode.after(this.row);
    } else {
      ev.target.parentNode.before(this.row);
    }
  }

  dragend(ev) {
    this.row = null;

    let index = [...ev.target.parentNode.children].indexOf(ev.target);

    const formData = new FormData();
    formData.set('id', ev.params.id);
    formData.set('position', index);

    this.element.ariaBusy = true;

    const req = new FetchRequest("patch", this.urlValue, {
      responseKind: "turbo-stream",
      body: formData,
    });

    req.perform()
      .then(() => {
        this.element.ariaBusy = false;
      })
      .catch((error) => {
        console.error(error);
        alert('Could not save position');
        window.location.reload();
      });
  }
}
