import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visits--build-visit"
export default class extends Controller {
  static get targets() {
    return ["visitScheduleSelect", "configuredKitsForVisit", "noVisitSelected", "buildVisitButton"]
  }

  connect() {
    this.fetchInventory()
  }
  
  fetchInventory(){
    var template_id = this.visitScheduleSelectTarget.value;
    if(template_id != ""){
      var data = {
        template_id: template_id
      }
      $.ajax({
        type: "GET",
        url: "fetch_configured_kits",
        data: data,
        dataType: "script",
      });
    } else {
      this.configuredKitsForVisitTarget.classList.add("d-none");
      this.noVisitSelectedTarget.classList.remove("d-none");
      this.buildVisitButtonTarget.disabled = true;
    }
  }
}
