import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="empty-page"
export default class extends Controller {
  static targets = ["entries", "content", "emptyMessage"];

  initialize() {
    this.observer = new MutationObserver(() => this.update());
  }

  connect() {
    this.observer.observe(this.entriesTarget, {
      childList: true,
    });

    this.update();
  }

  disconnect() {
    this.observer.disconnect();
  }

  update() {
    const hasEntries = this.entriesTarget.childElementCount > 0;

    if (this.hasEmptyMessageTarget) {
      this.emptyMessageTarget.classList.toggle("d-none", hasEntries);
    }

    const content = this.hasContentTarget
      ? this.contentTarget
      : this.entriesTarget;
    content.classList.toggle("d-none", !hasEntries);
  }
}
