import React from "react";

const Spinner = () => (
  <div className="attachment_uploader_spinner_outer_container">
    <div className="attachment_uploader_spinner_inner_container">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Spinner;
