import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    timepointId: String,
    originVisitTemplatesInstance: String,
    targetVisitTemplatesInstance: String
  };

  static targets = [
    "originSampleType", 
    "originVisitSchedule", 
    "originVisitTemplates",
    "targetSampleType", 
    "targetVisitSchedule", 
    "targetVisitTemplates",
    "originTimepoint",
    "targetTimepoint",
    "timepointToCopy",
    "targetedTimepoints",
    "modalSubmit",
    "overrideWarning"
  ];

  connect(){
    this.initializeMultiSelects()
  }

  fetchSchedules(event){
    get(`/sample_timepoints/${this.timepointIdValue}/fetch_copy_steps_origin_info?fetch_action=schedules&sample_type_id=${this.originSampleTypeTarget.value}`, { responseKind: 'turbo-stream' })
  }

  fetchVisitTemplates(event){
    get(`/sample_timepoints/${this.timepointIdValue}/fetch_copy_steps_origin_info?fetch_action=visit_templates&visit_schedule_id=${this.originVisitScheduleTarget.value}&sample_type_id=${this.originSampleTypeTarget.value}`, { responseKind: 'turbo-stream' })
  }

  fetchTargetSchedules(event){
    get(`/sample_timepoints/${this.timepointIdValue}/fetch_copy_steps_target_info?fetch_action=schedules&sample_type_id=${this.targetSampleTypeTarget.value}`, { responseKind: 'turbo-stream' })
  }

  fetchTargetVisitTemplates(event){
    get(`/sample_timepoints/${this.timepointIdValue}/fetch_copy_steps_target_info?fetch_action=visit_templates&visit_schedule_id=${this.targetVisitScheduleTarget.value}&sample_type_id=${this.targetSampleTypeTarget.value}`, { responseKind: 'turbo-stream' })
  }

  initializeMultiSelects(){
    if (this.originVisitTemplatesInstanceValue == "true"){
      const timepointId = this.timepointIdValue
      $("#origin-visit-template-select").on('changed.bs.select', function(event, clickedIndex, isSelected){
        const select = event.target;
        const clickedValue = select.options[clickedIndex].value;
        let updateAction
        if (isSelected){
          updateAction = "add"
        } else {
          updateAction = "remove"
        }
        get(`/sample_timepoints/${timepointId}/update_origin_timepoints?update_action=${updateAction}&clicked_visit_template_id=${clickedValue}&sample_type_id=${$("#origin_sample_type").find(":selected").val()}`, { responseKind: 'turbo-stream' })
      }); 
      $('.selectpicker').selectpicker('refresh');
    } else if (this.targetVisitTemplatesInstanceValue == "true"){
      const timepointId = this.timepointIdValue
      $("#target-visit-template-select").on('changed.bs.select', function(event, clickedIndex, isSelected){
        const select = event.target;
        const clickedValue = select.options[clickedIndex].value;
        let updateAction
        if (isSelected){
          updateAction = "add"
        } else {
          updateAction = "remove"
        }
        get(`/sample_timepoints/${timepointId}/update_target_timepoints?update_action=${updateAction}&clicked_visit_template_id=${clickedValue}&sample_type_id=${$("#target_sample_type").find(":selected").val()}`, { responseKind: 'turbo-stream' })
      }); 
      $('.selectpicker').selectpicker('refresh');
    }
  }

  updateSelectedTimepoint(event){
    const timepointRow = event.target
    const selectedTimepointId = timepointRow.dataset.timepointId
    if (!timepointRow.classList.contains('secondary-grey', 'selected')){
      timepointRow.classList.add('secondary-grey', 'selected')
      this.originTimepointTargets.forEach((timepoint)=>{
        if (timepoint.dataset.timepointId == selectedTimepointId){ return } else {
          timepoint.classList.remove('secondary-grey', 'selected')
        }
      })
      this.timepointToCopyTarget.setAttribute("value", timepointRow.dataset.timepointId)
    } else {
      timepointRow.classList.remove('secondary-grey', 'selected')
      this.timepointToCopyTarget.setAttribute("value", "")
    }
    this.handleSubmitButton()
  }

  updateSelectedTargetTimepoint(event){
    const timepointRow = event.target.closest(".target-timepoint")
    if (this.targetedTimepointsTarget.value == ""){
      var targetTimepoints = []
    } else {
      var targetTimepoints = this.targetedTimepointsTarget.value.split(",")
    }
    const timepointId = timepointRow.dataset.timepointId
    if (!timepointRow.classList.contains('selected')){
      this.selectTargetTimepoint(timepointRow)
      targetTimepoints.push(timepointId)
    } else {
      this.deSelectTargetTimepoint(timepointRow)
      targetTimepoints.splice(targetTimepoints.indexOf(timepointId), 1)
    }
    targetTimepoints = targetTimepoints.toString()
    this.targetedTimepointsTarget.setAttribute("value", targetTimepoints)
    this.handleSubmitButton()
  }

  handleSubmit(event){
    event.preventDefault()
    if (this.targetedTimepointsTarget.value != ""){
      console.log('submit the form');
      $("#copy_steps_form").submit()
    }
  }
  
  handleSubmitButton(){
    if (this.targetedTimepointsTarget.value == "" || this.timepointToCopyTarget.value == ""){
      this.modalSubmitTarget.setAttribute('disabled', "true")
      this.modalSubmitTarget.classList.add("disabled")
    } else {
      this.modalSubmitTarget.removeAttribute('disabled')
      this.modalSubmitTarget.classList.remove("disabled")
    }
    const count = this.targetedTimepointsTarget.value.split(",").length
    let buttonText
    if (this.targetedTimepointsTarget.value == ""){
      buttonText = "Copy to timepoint"
    } else if (count == 1){
      buttonText = "Copy to 1 timepoint"
    } else if (count > 1){
      buttonText = `Copy to ${count} timepoints`
    }
    this.modalSubmitTarget.setAttribute("value", buttonText)
    this.handleOverrideWarning()
  }

  handleOverrideWarning(){
    if (this.targetedTimepointsTarget.value == ""){
      this.overrideWarningTarget.classList.add('d-none')
    } else {
      let show_warning = false
      this.targetTimepointTargets.forEach(target => {
        if (target.classList.contains("selected") && target.dataset.overrideWarning == "true"){
          show_warning = true
        }
      })
      if (show_warning){
        this.overrideWarningTarget.classList.remove('d-none')
      } else {
        this.overrideWarningTarget.classList.add('d-none')
      }
    }
  }

  clearAllTargetTimepoints(event){
    this.targetTimepointTargets.forEach((timepoint)=>{
      this.deSelectTargetTimepoint(timepoint)
    })
    this.targetedTimepointsTarget.setAttribute("value", "")
    this.handleSubmitButton()
  }

  selectAllTargetTimepoints(event){
    var targetTimepoints = []
    this.targetTimepointTargets.forEach((timepoint)=>{
      this.selectTargetTimepoint(timepoint)
      targetTimepoints.push(timepoint.dataset.timepointId)
    })
    targetTimepoints = targetTimepoints.toString()
    this.targetedTimepointsTarget.setAttribute("value", targetTimepoints)
    this.handleSubmitButton()
  }

  selectTargetTimepoint(timepointRow){
    timepointRow.classList.add('selected')
    if (timepointRow.dataset.overrideWarning == 'true'){
      timepointRow.classList.add('alert-danger')
    } else {
      timepointRow.classList.add('secondary-grey')
    }
    $(timepointRow).children(".fa-square-o").addClass("d-none")
    $(timepointRow).children(".fa-check-square").removeClass("d-none")    
  }

  deSelectTargetTimepoint(timepointRow){
    timepointRow.classList.remove('secondary-grey', 'selected', 'alert-danger')
    $(timepointRow).children(".fa-square-o").removeClass("d-none")
    $(timepointRow).children(".fa-check-square").addClass("d-none")
  }
}
