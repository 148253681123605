import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["confirmationModal", "reasonSelect"];
  static values = {
    editForm: String
  };

  connect (){
    if (this.editFormValue == "true"){
      $('.datepicker').datepicker({
        clearBtn: true,
        format: 'dd-M-yyyy',
        keepEmptyValues: true,
        autoclose: true
      }).on('changeDate', (ev) => {
        this.manageOtherAliquots();
      });
      $('.timepicker').timepicker().on('changeTime.timepicker', this.manageOtherAliquots);
    }
  }

  triggerModal(e){
    e.preventDefault()
    $(this.confirmationModalTarget).modal();
  }

  toggleModal(e){
    $(this.confirmationModalTarget).modal('hide');
  }

  promptReason(e){
    let reasonField = $(this.confirmationModalTarget).find("#reason-field")
    $(reasonField).removeClass("d-none")
    $(reasonField).addClass("d-flex")
  }
  
  toggleSubmitButtons(){
    let submitButtons = $(this.confirmationModalTarget).find("#submit-buttons")
    if (this.reasonSelectTarget.value == ""){
      $(submitButtons).addClass("d-none")
    } else {
      $(submitButtons).removeClass("d-none")
    }
  }

  manageOtherAliquots(){
    $("#shipped-submit").removeClass("d-none")
    $("#pending-submit").addClass("d-none")
    $("#other-aliquots-span").removeClass("d-none")
  }
}
