import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="refresh-elements"
export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "turbo:frame-render",
      this.refreshElements.bind(this)
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:frame-render",
      this.refreshElements.bind(this)
    );
  }

  refreshElements() {
    $(".datepicker").datepicker();
  }
}
