import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--switched-field"
export default class extends Controller {
  static targets = ["field"]

  static values = {
    clear: { type: Boolean, default: false },
  }

  update(ev) {
    if (!this.hasFieldTarget) {
      return;
    }

    const disabled = !ev.target.checked;
    this.fieldTarget.disabled = disabled;

    if (this.clearValue && disabled) {
      this.fieldTarget.value = '';
    }
  }
}
