import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

const TOOLTIP_ID = "nested-donut-tooltip";

function createDonutTooltip() {
  const elem = document.getElementById(TOOLTIP_ID);
  if (elem) return d3.select(elem);

  let tooltip = d3
    .create("div")
    .attr("id", TOOLTIP_ID)
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px");

  document.body.appendChild(tooltip.node());

  return tooltip;
}

// Connects to data-controller="nested-donut"
export default class extends Controller {
  static values = {
    size: Number,
    data: Array,
    total: Number,
    ringThickness: { type: Number, default: 20 },
    ringShellGap: { type: Number, default: 2 },
  };

  initialize() {
    this.numberFormatter = new Intl.NumberFormat(undefined, {
      style: "decimal",
    });

    this.percentFormatter = new Intl.NumberFormat(undefined, {
      style: "percent",
      maximumFractionDigits: 1,
    });
  }

  connect() {
    this.tooltip = createDonutTooltip();

    const height = this.sizeValue;
    const width = this.sizeValue;
    const radius = height / 2;

    const svg = d3
      .create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto;");

    this.addDataSeries(svg, radius, this.dataValue);

    this.element.replaceChildren(svg.node());
  }

  addDataSeries(svg, radius, data, start = 0, end = 2 * Math.PI) {
    const ringThickness = this.ringThicknessValue;
    const ringShellGap = this.ringShellGapValue;

    const arc = d3
      .arc()
      .innerRadius(radius - ringThickness)
      .outerRadius(radius - ringShellGap);

    const pie = d3
      .pie()
      .padAngle((ringShellGap * 2) / radius)
      .value((d) => d.value)
      .sort((a, b) => d3.descending(a.value, b.value))
      .startAngle(start)
      .endAngle(end);

    const tweenPie = (b) => {
      const interp = d3.interpolate({ startAngle: 0, endAngle: 0 }, b);
      return (t) => arc(interp(t));
    };

    const pieData = pie(data);

    svg
      .append("g")
      .selectAll()
      .data(pie(data))
      .join("path")
      .attr("fill", (d) => d.data.color)
      .on("mouseover", (event, d) => {
        const value = this.numberFormatter.format(d.data.value);
        const pct = this.percentFormatter.format(
          d.data.value / this.totalValue
        );
        this.tooltip
          .html(`${d.data.name} — ${value} (${pct})`)
          .style("opacity", 1);
      })
      .on("click", (event, d) => {
        if (d.data.path) {
          Turbo.visit(new URL(d.data.path, window.location.origin));
        }
      })
      .on("mousemove", (event) => {
        this.tooltip
          .style("left", event.clientX + 10 + "px")
          .style("top", event.clientY + "px");
      })
      .on("mouseleave", () => {
        this.tooltip.style("opacity", 0);
      })
      .transition()
      .ease(d3.easeCubicOut)
      .duration(750)
      .attrTween("d", tweenPie);

    pieData
      .filter((entry) => Object.hasOwn(entry.data, "children"))
      .forEach((entry) => {
        this.addDataSeries(
          svg,
          radius - ringThickness - ringShellGap,
          entry.data.children,
          entry.startAngle,
          entry.endAngle
        );
      });
  }
}
