import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile--breadcrumbs"
export default class extends Controller {
  connect() {
    this.active = this.element.querySelector(".active");
    this.scroll();
  }

  scroll() {
    if (this.active) {
      this.active.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  }
}
