import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="character-counter"
export default class extends Controller {
  static get targets() { return ["input", "count", "max"]}

  initialize() {
    this.maxTarget.innerHTML = this.inputTarget.maxLength;
    this.countTarget.innerHTML = this.inputTarget.value.length || '0';
  }

  keyup(ev) {
    this.countTarget.innerHTML = ev.target.value.length;
  }
}
