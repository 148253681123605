document.addEventListener("turbolinks:load", () => {

   document.addEventListener('keydown', (event) => {
        if (event.key == "ArrowLeft"){
            event.preventDefault();
            $('.carousel').carousel("prev");
        }
    
        if (event.key == "ArrowRight"){
            event.preventDefault();
            $('.carousel').carousel("next");
        }
      });
});