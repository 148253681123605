import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static targets = ["loadMore"]

  options = {
    threshold: 0
  }

  connect() {
    useIntersection(this, this.options);
  }

  startedLoading() {
    const loader = document.createElement('button');
    loader.disabled = true;
    loader.classList.add('btn', 'btn-secondary');

    const spinner = document.createElement('span');
    spinner.classList.add('spinner-border', 'spinner-border-sm', 'me-2');
    const text = document.createTextNode('Loading next page');
    loader.append(spinner, text);

    this.loadMoreTarget.replaceWith(loader);
  }

  appear() {
    if (this.hasLoadMoreTarget) {
      this.loadMoreTarget.click();
    }
  }
}
