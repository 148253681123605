import { Controller } from "@hotwired/stimulus";

const ENFORCED_EXPIRATION_ALERT_DAYS = new Set(["30"]);

// Connects to data-controller="inventory--form"
export default class extends Controller {
  static targets = [
    "inventoryType",
    "typeField",
    "byo",
    "disabledByByo",
    "expiration",
    "alertDay",
    "storageArea",
    "newStorageArea",
    "image",
    "removeImage",
  ];

  connect() {
    this.creatingStorageArea = false;
    this.wasByo = null;

    this.typeChanged();
    this.updateByo();
    this.updateExpirationAlerts();
    this.updateImageClear();
  }

  /**
   * Update fields that should be displayed only for certain inventory types.
   */
  typeChanged() {
    /** @type {HTMLSelectElement} */
    const select = this.inventoryTypeTarget;
    const selectedValue = select.options[select.selectedIndex].value;

    this.typeFieldTargets.forEach((target) => {
      const shouldDisplay = target.dataset.onlyType === selectedValue;
      target.classList.toggle("d-none", !shouldDisplay);
    });

    this.updateByo();
  }

  byoChanged() {
    this.updateByo();
  }

  updateByo() {
    if (!this.hasByoTarget) return;

    const checked = this.byoTarget.checked;

    // Only change values when BYO is actually changed.
    if (this.wasByo === checked) return;
    this.wasByo = checked;

    this.disabledByByoTargets.forEach((target) => {
      // If attribute is set, only use it as a default, don't enforce it.
      if (target.hasAttribute("data-byo-toggle")) {
        target.checked = !checked;
      } else {
        const isCheckbox = target.type === "checkbox";
        // If checked (BYO enabled), reset the value of each field.
        if (checked) {
          if (!isCheckbox) target.value = "";
          target.checked = false;
        } else {
          target.checked = target.dataset.default || false;
        }

        target.disabled = checked;
      }
    });

    this.updateExpirationAlerts();
  }

  includeExpiration() {
    this.updateExpirationAlerts();
  }

  updateExpirationAlerts() {
    const expirationEnabled = this.expirationTarget.checked;

    this.alertDayTargets.forEach((target) => {
      target.disabled = !expirationEnabled;
      if (!expirationEnabled) {
        target.checked = false;
      }
    });

    if (expirationEnabled) {
      this.alertDayTargets
        .filter((target) => ENFORCED_EXPIRATION_ALERT_DAYS.has(target.value))
        .forEach((checkbox) => {
          checkbox.disabled = true;
          checkbox.checked = true;
        });
    }
  }

  toggleNewStorageArea(ev) {
    this.creatingStorageArea = !this.creatingStorageArea;

    const text = this.creatingStorageArea
      ? "Hide Form"
      : "Add a New Storage Area";
    ev.target.replaceChildren(document.createTextNode(text));

    this.storageAreaTarget.disabled = this.creatingStorageArea;
    if (this.creatingStorageArea) {
      this.storageAreaTarget.value = "";
    }
    this.newStorageAreaTarget.classList.toggle(
      "d-none",
      !this.creatingStorageArea
    );
  }

  updateImageClear() {
    const hasNoFiles = this.imageTarget.files.length === 0;
    this.removeImageTarget.disabled = hasNoFiles;
  }

  removeImage() {
    this.imageTarget.value = "";
    this.updateImageClear();
  }
}
