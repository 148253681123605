import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    configId: String
  };
  
  fetchSites(event){
    get(`/configuration/${this.configIdValue}/fetch_copy_config_data?organization_id=${event.target.value}`, { responseKind: 'turbo-stream' })
  }

  fetchWorkspaces(event){
    get(`/configuration/${this.configIdValue}/fetch_copy_config_data?site_id=${event.target.value}`, { responseKind: 'turbo-stream' })
  }

  fetchKits(event){
    get(`/configuration/${this.configIdValue}/fetch_copy_config_data?workspace_id=${event.target.value}`, { responseKind: 'turbo-stream' })
  }
}
