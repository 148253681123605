import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  connect() {
    const form = this.element;
    if (!(form instanceof HTMLFormElement)) {
      throw new Error("Must be attached to form element");
    }

    form.requestSubmit();
  }
}
