import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return [
            "formControl", 
            "dropdownOptions", 
            "resuspensionOptions", 
            "resuspensionPeriodInput", 
            "dropdownOptionsInput", 
            "preset",
            "hidden",
            "defaultValue",
            "required"
        ]
    }

    toggleControlOptions(event){
        if (this.formControlTarget.value == "dropdown") {
            this.dropdownOptionsTarget.classList.remove("d-none");
            this.dropdownOptionsInputTarget.setAttribute("required", true)
            this.resuspensionOptionsTarget.classList.add("d-none");
            this.resuspensionPeriodInputTarget.removeAttribute("required")
            this.presetTarget.removeAttribute("disabled")
            this.hiddenTarget.removeAttribute("disabled")
            this.defaultValueTarget.removeAttribute("disabled")
            this.requiredTarget.removeAttribute("disabled")
        } else if (this.formControlTarget.value == "resuspension") {
            this.dropdownOptionsTarget.classList.add("d-none");
            this.resuspensionOptionsTarget.classList.remove("d-none");
            this.dropdownOptionsInputTarget.removeAttribute("required")
            this.resuspensionPeriodInputTarget.setAttribute("required", true)
            this.presetTarget.setAttribute("disabled", true)
            this.hiddenTarget.setAttribute("disabled", true)
            this.defaultValueTarget.setAttribute("disabled", true)
            this.requiredTarget.setAttribute("disabled", true)
        } else if (this.formControlTarget.value == "text") {
            this.dropdownOptionsTarget.classList.add("d-none");
            this.resuspensionOptionsTarget.classList.add("d-none");
            this.dropdownOptionsInputTarget.removeAttribute("required")
            this.resuspensionPeriodInputTarget.removeAttribute("required")
            this.presetTarget.removeAttribute("disabled")
            this.hiddenTarget.removeAttribute("disabled")
            this.defaultValueTarget.removeAttribute("disabled")
            this.requiredTarget.removeAttribute("disabled")
        }
    }
}
