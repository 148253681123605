import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ["numberField"]
    }

    connect(){
        this.initializeNumberButtons()
        this.enforceMinMax()
        this.enforceSimpleWholeNumbers()
    }

    initializeNumberButtons(){
        this.numberFieldTargets.forEach((field)=>{
            if (field.dataset.spinner != "false"){
                $(field).inputSpinner()
            }
        })
    }

    enforceMinMax(){
        this.numberFieldTargets.forEach((field)=>{
            field.addEventListener('change', ()=>{
                if (field.value != "") {
                    if (parseInt(field.value) < parseInt(field.min)) {
                        field.value = field.min;
                    }
                    if (parseInt(field.value) > parseInt(field.max)) {
                        field.value = field.max;
                    }
                }
            })
        })
    }

    enforceSimpleWholeNumbers(){
        this.numberFieldTargets.forEach((field)=>{
            if (field.dataset.simpleWholeNumbers == "true"){
                field.addEventListener('keydown', (event)=>{
                    var regex = new RegExp("^[0-9]*$");
                    if(event.ctrlKey // (A)
                        || event.altKey // (A)
                        || typeof event.key !== 'string' // (B)
                        || event.key.length !== 1) { // (C)
                            return;
                    }
                        
                    if(!regex.test(event.key)) {
                        event.preventDefault();
                    }
                })

            }
        })
    }
}
