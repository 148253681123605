import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    passwordAction: String,
    ssoAction: String,

    domainWithout: Array,
    domainWith: Array,
  };

  static targets = ["submit", "email", "ssoHidden"];

  connect() {
    this.domainWithout = new Set(this.domainWithoutValue);
    this.domainWith = new Set(this.domainWithValue);

    this.emailFieldName = this.emailTarget.name;
    this.wasSSO = false;
  }

  /**
   * Update form when user email is changed.
   * @param {InputEvent} ev 
   */
  keyup({ target: { value } }) {
    const isSSO = this.looksLikeSSO(value);
    this.setMode(isSSO);
  }

  /**
   * Check if a given email looks like a SSO domain.
   * @param {string} email 
   * @returns {boolean}
   */
  looksLikeSSO(email) {
    const emailParts = email.split('@');
    if (emailParts.length !== 2) {
      return false;
    }

    const [prefix, domain] = emailParts;

    if (this.domainWithout.has(domain)) {
      return true;
    }

    if (!prefix.includes('+') && this.domainWith.has(domain)) {
      return true;
    }

    return false;
  }

  /**
   * Set if the form should function as password or SSO.
   * @param {boolean} sso 
   */
  setMode(sso) {
    if (sso === this.wasSSO) {
      return;
    }

    if (sso) {
      this.submitTarget.setAttribute('formaction', this.ssoActionValue);
      this.submitTarget.setAttribute('formmethod', 'GET');
      this.submitTarget.value = 'Log in with SSO';
      this.emailTarget.name = 'email';
    } else {
      this.submitTarget.setAttribute('formaction', this.passwordActionValue);
      this.submitTarget.setAttribute('formmethod', 'POST');
      this.submitTarget.value = 'Log in';
      this.emailTarget.name = this.emailFieldName;
    }

    this.ssoHiddenTargets.forEach((target) => {
      target.classList.toggle('d-none', sso);
    });

    this.wasSSO = sso;
  }
}
