import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

const MINIMUM_LOADING_TIME_MS = 500;

function abortEvent(ev) {
  ev.stopPropagation();
}

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [
    "check",
    "clear",
    "input",
    "menu",
    "model",
    "queryText",
    "result",
    "results",
  ];

  static values = {
    dropdown: Boolean,
  };

  timeout = null;
  models = new Set();
  selectedResultIndex = null;
  hasPerformedSearch = false;
  abortController = null;

  connect() {
    clearTimeout(this.timeout);

    this.selectedResultIndex = null;

    this.query = this.hasInputTarget ? this.inputTarget.value : "";

    if (this.dropdownValue) {
      this.resultContext = "dropdown";

      // Clear query in dropdown when already on search page.
      const currentPageParams = new URLSearchParams(window.location.search);
      if (currentPageParams.has("static_query")) {
        this.query = "";
        if (this.hasInputTarget) this.inputTarget.value = "";
      }

      this.$menu = $(this.menuTarget);
      this.$menu.on("click.bs.dropdown", abortEvent);
      this.$menu.parent().on("shown.bs.dropdown", this.focus.bind(this));
      this.$menu
        .parent()
        .on("hidden.bs.dropdown", this.dropdownHidden.bind(this));
    } else {
      this.resultContext = "page";

      $(this.element).on("click", "a.page-link", this.pageClicked.bind(this));

      // The results page doesn't load the results, do it here instead.
      if (!this.hasPerformedSearch) {
        this.updateSearch();
      }
    }

    this.updateClearButton();
    this.updateResultVisibility();
  }

  disconnect() {
    clearTimeout(this.timeout);
    if (this.abortController) this.abortController.abort();

    if (this.$menu) {
      this.$menu.off("click.bs.dropdown", null, abortEvent);
      this.$menu.parent().off("shown.bs.dropdown", null, this.focus.bind(this));
      this.$menu
        .parent()
        .off("hidden.bs.dropdown", this.dropdownHidden.bind(this));
    }

    $(this.element).off("click", "a.page-link", this.pageClicked.bind(this));
  }

  modelTargetConnected(elem) {
    if (elem.checked) {
      this.models.add(elem.value);
    } else {
      this.models.delete(elem.value);
    }

    this.updateCheckToggle();
  }

  /**
   * Close the menu.
   */
  close() {
    if (this.$menu) {
      this.$menu.removeClass("show");
    }
  }

  /**
   * Clear the current search and reset results.
   * @param {Event} ev
   */
  clear(ev) {
    ev.preventDefault();

    clearTimeout(this.timeout);
    if (this.abortController) this.abortController.abort();

    if (this.query.length === 0) {
      return;
    }

    this.query = this.inputTarget.value = "";
    this.updateClearButton();
    this.updateSearch();
  }

  /**
   * Update if clear button is displayed, based on if text has been entered.
   */
  updateClearButton() {
    if (!this.hasClearTarget) return;
    this.clearTarget.classList.toggle("d-none", this.query.length === 0);
  }

  /**
   * Update if main result content is visible.
   *
   * This is used to hide the results/filters when a user first opens
   * the search box.
   */
  updateResultVisibility() {
    const resultsHidden = this.query.length === 0 && !this.hasPerformedSearch;
    this.resultsTarget.classList.toggle("d-none", resultsHidden);
  }

  /**
   * Run on every keystroke, used to fetch new results after the user stops typing.
   * @param {Event} ev
   */
  debounce({ target }) {
    this.query = target.value;
    this.updateClearButton();

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateSearch();
    }, 325);
  }

  /**
   * Update the results based on the current query and selected filters.
   */
  async updateSearch(page = null) {
    const startTime = Date.now();

    clearTimeout(this.timeout);

    if (this.abortController) this.abortController.abort();
    this.abortController = new AbortController();

    this.hasPerformedSearch = true;
    this.updateResultVisibility();

    this.element.ariaBusy = "true";
    if (this.hasQueryTextTarget) this.queryTextTarget.textContent = this.query;

    let url = new URL(window.location);
    let params = new URLSearchParams(url.search);

    params.set("static_query", this.query);
    params.set("search_models", Array.from(this.models).join(","));
    params.set("context", this.resultContext);

    if (page) {
      params.set("page", page);
    }

    url.search = params;

    try {
      let resp = await fetch(url, {
        signal: this.abortController.signal,
        headers: {
          accept:
            "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        },
      });

      if (resp.status !== 200) {
        throw new Error(
          `Got unexpected status code when searching: ${resp.status}`
        );
      }

      const contentType = resp.headers.get("content-type");
      if (contentType?.match(/^text\/vnd\.turbo-stream\.html/)) {
        const elapsedTime = Date.now() - startTime;
        const delayTime = MINIMUM_LOADING_TIME_MS - elapsedTime;
        if (delayTime > 0) {
          await new Promise((r) => setTimeout(r, delayTime));
        }

        let text = await resp.text();
        Turbo.renderStreamMessage(text);
      } else {
        throw new Error(`Search response was not Turbo Stream: ${contentType}`);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error(error);
        if (this.query.length !== 0) {
          alert("Could not load search results. Please try again.");
          window.location.reload();
        }
        return;
      }
    }

    this.selectedResultIndex = null;
    this.updateSelectedResult();
    this.updateCheckToggle();

    this.element.ariaBusy = "false";

    this.focus();
  }

  /**
   * Toggle a model's inclusion in search.
   * @param {MouseEvent} ev
   */
  toggle(ev) {
    /** @type {HTMLInputElement} */
    const target = ev.target;
    const model = target.value;

    this.checkTarget.checked = false;
    this.checkTarget.parentNode.classList.remove("active");

    // When every model is already selected, empty selection.
    if (this.models.size === this.modelTargets.length) {
      this.modelTargets.forEach(
        (target) => (target.checked = model === target.value)
      );
      this.models.clear();
    }

    target.parentNode.classList.toggle("active", target.checked);

    if (target.checked) {
      this.models.add(model);
    } else {
      this.models.delete(model);
    }

    this.updateCheckToggle();
    this.updateSearch();
  }

  /**
   * Focus the input field.
   */
  focus() {
    this.inputTarget.focus();
  }

  /**
   * Called when dropdown was hidden, used to hide results if needed.
   */
  dropdownHidden() {
    this.hasPerformedSearch = false;
    this.updateResultVisibility();
  }

  /**
   *
   * @param {MouseEvent} ev
   */
  pageClicked(ev) {
    const href = ev.target.getAttribute("href");
    if (!href) return;

    ev.preventDefault();

    const url = new URL(href, window.location);
    this.updateSearch(url.searchParams.get("page"));
  }

  /**
   * Update the all filter checkbox.
   */
  updateCheckToggle() {
    const allChecked =
      this.checkTarget.checked || this.models.size === this.modelTargets.length;

    this.checkTarget.parentNode.classList.toggle("active", allChecked);
    this.syncButton(this.checkTarget, this.checkTarget.dataset.searchSyncParam);

    this.modelTargets.forEach((target) => {
      target.checked &= !allChecked;
      target.parentNode.classList.toggle("active", target.checked);
      this.syncButton(target, target.dataset.searchSyncParam);
    });

    if (allChecked) {
      this.modelTargets.forEach((target) => {
        this.models.add(target.value);
      });
    }
  }

  /**
   * Sync the check and active state of buttons between elements.
   * @param {HTMLInputElement} target
   * @param {string} name
   */
  syncButton(target, name) {
    const elem = document.getElementById(name);
    if (!elem) return;

    elem.checked = target.checked;
    elem.parentNode.classList.toggle(
      "active",
      target.parentNode.classList.contains("active")
    );
  }

  /**
   * Toggle if all of the filters should be checked, then update search.
   */
  checkAll() {
    this.updateCheckToggle();
    this.updateSearch();
  }

  /**
   * Navigate up one result
   * @param {KeyboardEvent} ev
   */
  up(ev) {
    ev.preventDefault();

    if (this.selectedResultIndex === null) {
      this.selectedResultIndex = 0;
    } else if (this.selectedResultIndex > 0) {
      this.selectedResultIndex -= 1;
    }

    this.updateSelectedResult();
  }

  /**
   * Navigate down one result
   * @param {KeyboardEvent} ev
   */
  down(ev) {
    ev.preventDefault();

    if (this.selectedResultIndex === null) {
      this.selectedResultIndex = 0;
    } else if (this.selectedResultIndex <= this.resultTargets.length - 2) {
      this.selectedResultIndex += 1;
    }

    this.updateSelectedResult();
  }

  /**
   * Mark the selected result index and scroll if needed.
   */
  updateSelectedResult() {
    this.resultTargets.forEach((target, index) => {
      const active = index === this.selectedResultIndex;
      target.classList.toggle("border-dark", active);
    });

    if (this.selectedResultIndex !== null) {
      /** @type {HTMLElement} */
      const activeResult = this.resultTargets[this.selectedResultIndex];

      const block = this.selectedResultIndex === 0 ? "end" : "nearest";

      activeResult.scrollIntoView({
        behavior: "smooth",
        block,
      });
    }
  }

  /**
   * Navigiate to a specific result if selected, otherwise open result page.
   * @param {Event} ev
   */
  visit(ev) {
    ev.preventDefault();

    if (this.selectedResultIndex === null) {
      if (this.dropdownValue) {
        const url = new URL(window.location.href);

        url.searchParams.set("static_query", this.query);
        url.searchParams.set("search_models", [...this.models].join(","));

        Turbo.visit(url);
      } else {
        this.updateSearch();
      }
    } else {
      /** @type {HTMLElement} */
      const result = this.resultTargets[this.selectedResultIndex];

      if (result.hasAttribute("href")) {
        result.click();
      } else {
        result.querySelector('[data-search-target="link"]')?.click();
      }
    }
  }

  /**
   * Open the Intercom support widget.
   */
  support() {
    if (window["Intercom"]) {
      Intercom("show");
    }
  }
}
