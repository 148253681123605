/* eslint no-console:0 */

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails"
Turbo.session.drive = true

import "jquery";
import "jquery-ui-bundle";

import "bootstrap";
import "bootstrap-timepicker";
import "bootstrap-select";
import "bootstrap/js/dist/tooltip";

import "chartkick";
import "chart.js";
import "nprogress";

import ahoy from "ahoy.js";
import * as L from "leaflet";
import "leaflet-extra-markers";

import "../controllers";

// ---------------------------------------
// REACT
// ---------------------------------------
const componentRequireContext = require.context("components", true);
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.useContext(componentRequireContext);

// ---------------------------------------
// LOCAL
// ---------------------------------------
// legacy jQuery
import "../src/main.js.erb";

// ---------------------------------------
// STYLESHEETS
// ---------------------------------------
import "../../assets/stylesheets/application";

Rails.start();

ReactRailsUJS.detectEvents();

require("@rails/activestorage").start();

const images = require.context("../../assets/images", true)