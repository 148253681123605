import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import { post } from '@rails/request.js'

// Connects to data-controller="visit-calendar"
export default class extends Controller {
  static get targets() {
    return ["visitsContainer"]
  }

  connect(){
    $('[data-toggle="tooltip"]').tooltip();
    this.loadPreferredView()
    this.enableDragToReschedule()
  }

  loadPreferredView(){
    const viewPreference = localStorage.getItem(this.storagePrefix());
    if (viewPreference == 'byMonth' && this.element.dataset.viewType != "month") {
      get(`/${this.resourceType()}s/${this.resourceId()}/visits_by_month`, { responseKind: 'turbo-stream' })
    } else if (viewPreference == "byWeek" && this.element.dataset.viewType != "week") {
      get(`/${this.resourceType()}s/${this.resourceId()}/visits_by_week`, { responseKind: 'turbo-stream' })
    } else {
      $(".selectpicker").selectpicker({
        noneSelectedText: ''
      });
    }
  }

  enableDragToReschedule(){
    if (this.element.dataset.viewType != "list" && this.resourceType() == "workspace") {
      Array.from(this.element.getElementsByClassName("draggable")).forEach(this.initializeDraggable); 
      Array.from(this.element.getElementsByClassName("day")).forEach(this.initializeDroppable); 
    }
  }

  resourceId(){
    return this.element.dataset.resourceId
  }

  resourceType(){
    return this.element.dataset.resourceType
  }

  storagePrefix(){
    return `${this.resourceType()}_visits_${this.resourceId()}`
  }

  byMonth(event){
    localStorage.setItem(this.storagePrefix(), "byMonth");
    var path = `/${this.resourceType()}s/${this.resourceId()}/visits_by_month`
    if (event.currentTarget.dataset.startDate != undefined) {
        path = `${path}?start_date=${event.currentTarget.dataset.startDate}`
      }
    get(path, { responseKind: 'turbo-stream' })
  }

  byWeek(event){
    localStorage.setItem(this.storagePrefix(), "byWeek");
    var path = `/${this.resourceType()}s/${this.resourceId()}/visits_by_week`
    if (event.currentTarget.dataset.startDate != undefined) {
      path = `${path}?start_date=${event.currentTarget.dataset.startDate}`
    }
    get(path, { responseKind: 'turbo-stream' })
  }
  

  byList(event){
    localStorage.setItem(this.storagePrefix(), "byList");
    get(`/${this.resourceType()}s/${this.resourceId()}/visits_by_list`, { responseKind: 'turbo-stream' })
  }
  
  initializeDraggable(targetElement) {
    $(targetElement).draggable({
      containment: "table",
      scroll: false,
      stop: function () {
          // console.log('drag stopped');
        },
    });
  }
  
  initializeDroppable(targetElement){
    $(targetElement).droppable({
      drop: function (event, ui) {
        var date = $(this).find(".date_string").data("date");
        var calendar_type = $(this).find('.calendar_type').data('calendar-type');
        var subject_visit_id = ui.draggable
          .find(".subject_visit_id")
          .data("subject-visit-id");
        var path = `/subject_visits/${subject_visit_id}/change_visit_date?date=${date}&calendar_type=${calendar_type}`
        post(path, { responseKind: 'turbo-stream' })

        // start spinner over whole table
        $(".simple-calendar table").LoadingOverlay("show", {
          imageAutoResize: false,
          size: "64px",
        });
      },
    });
  }

}
