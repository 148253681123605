import { Controller } from "@hotwired/stimulus"

function uploadFile(file, uploadUrl, onProgress, onFinish, onError) {
  const csrf = document.querySelector('meta[name="csrf-token"]').content;

  const formData = new FormData();
  formData.append('file', file);

  const xhr = new XMLHttpRequest();

  xhr.upload.onprogress = (ev) => {
    onProgress(ev.loaded / ev.total);
  };

  xhr.onload = () => {
    if (xhr.status !== 200) {
      onError(xhr.status);
    } else {
      onFinish(xhr.responseText);
    }
  };

  xhr.onerror = (error) => {
    onError(error);
  }

  xhr.open('POST', uploadUrl);
  xhr.setRequestHeader('x-csrf-token', csrf);
  xhr.send(formData);
}

export default class extends Controller {
  static get targets() {
    return ["fileInput", "fileDrop", "uploadingList", "doneButton"]
  }

  static values = {
    url: String,
  }

  connect() {
    this.uploadingFiles = new Map();
  }

  fileClick() {
    this.fileInputTarget.click();
  }

  filesChanged(ev) {
    [...ev.target.files].forEach((file) => this.uploadFile(file));
    ev.target.value = '';
  }

  filesDragged(ev) {
    ev.preventDefault();
    this.fileDropTarget.classList.add('dragging');
  }

  filesLeft() {
    this.fileDropTarget.classList.remove('dragging');
  }

  filesDropped(ev) {
    ev.preventDefault();
    this.fileDropTarget.classList.remove('dragging');

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          this.uploadFile(file);
        }
      })
    } else {
      [...ev.dataTransfer.files].forEach((file) => {
        this.uploadFile(file);
      })
    }
  }

  uploadFile(file) {
    const name = file.name;
    console.debug(`Starting upload for ${name}`);

    if (file.type !== 'application/pdf') {
      alert(`Could not upload ${name}, only PDF files are supported`);
      return;
    }

    this.uploadingFiles.set(file.name, 0);
    this.updateUploadingList();

    uploadFile(file, this.urlValue, (progress) => {
      this.uploadProgressed(name, progress);
    }, (html) => {
      this.uploadFinished(name);
      Turbo.renderStreamMessage(html);
    }, (error) => {
      console.error(error);
      alert(`Could not upload file: ${error}`);
    });
  }

  uploadProgressed(filename, progress) {
    this.uploadingFiles.set(filename, progress);
    this.updateUploadingList();
  }

  uploadFinished(filename) {
    this.uploadingFiles.delete(filename);
    this.updateUploadingList();
  }

  updateUploadingList() {
    this.doneButtonTarget.disabled = this.uploadingFiles.size > 0;

    const elems = [];

    for (let [key, value] of this.uploadingFiles) {
      if (value !== true) {
        const elem = document.createElement('li');
        elem.appendChild(document.createTextNode(key));
        elem.appendChild(this.progressBar(value));
        elems.push(elem);
      }
    }

    this.uploadingListTarget.replaceChildren(...elems);
  }

  progressBar(value) {
    const percentage = `${Math.round(value * 100)}%`;

    const container = document.createElement('div');
    container.classList.add('progress');

    const bar = document.createElement('div');
    bar.classList.add('progress-bar');
    bar.role = 'progressbar';
    bar.style.width = percentage;
    bar.appendChild(document.createTextNode(percentage));
    container.appendChild(bar);

    return container;
  }
}
