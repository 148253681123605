import { Controller } from "@hotwired/stimulus";

export default class extends Controller{

  initialize() {
    this.checkedIds = new Set();
  }

  checkedRows(event) {
    this.checkedIds = event.detail.ids;
    const ids = Array.from(event.detail.ids);
    if (ids.length) {
      $.ajax({
        type: "GET",
        url: `/batches/check_work_orders/?work_order_ids=${ids}`,
        dataType: "script",
      });
    }
  }

  approveSelected(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const ids = Array.from(this.checkedIds).join(",")
      const batch_id = event.target.getAttribute("data-batch-id")
      window.location.href =
      `/batches/${batch_id}/approve_work_orders/?work_order_ids=${ids}&redirect_to=${location.href}`
    }
  }

  revertSelected(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const ids = Array.from(this.checkedIds).join(",")
      const batch_id = event.target.getAttribute("data-batch-id")
      window.location.href =
      `/batches/${batch_id}/revert_work_orders/?work_order_ids=${ids}&redirect_to=${location.href}`
    }
  }
}