import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["modal"]
  }

  connect() {
    this.target = $(this.modalTarget);
  }

  open() {
    this.target.modal('show');
    // initialize datepickers, input spinners, and tooltips that appear inside turbo-modals
    $(this.element).find('.selectpicker').selectpicker('refresh');
    $('.datepicker').datepicker();
    $("input[type='number']").not('.no-input-spinner').inputSpinner();
    $('[data-toggle="tooltip"]').tooltip()
  }

  close() {
    this.target.modal('hide');
  }
}
