import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form--remove-field"
export default class extends Controller {
  static targets = ["field", "button"];

  static values = {
    minimum: { type: Number, default: 0 },
    group: { type: Boolean, default: false },
  };

  connect() {
    this.updateButtons();
  }

  remove({ params }) {
    document.getElementById(params["id"])?.remove();

    this.updateButtons();
  }

  updateButtons() {
    const fieldCount = this.fieldTargets.length;
    const removeButtonDisabled = fieldCount <= this.minimumValue;

    this.buttonTargets.forEach((button) =>
      button.classList.toggle("d-none", removeButtonDisabled)
    );

    if (this.groupValue) {
      this.fieldTargets.forEach((field) => {
        field.classList.toggle("input-group", !removeButtonDisabled);
      });
    }
  }
}
