import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    planId: String
  }
  
  static targets = ["raceField", "ethnicityField", "raceRequired", "ethnicityRequired", "raceLabel", "ethnicityLabel", "screeningIdRequired", "screeningIdFields", "screeningIdLength"];

  connect(){
    // console.log("connected!");
  }
  
  toggleRaceForm(event){
    if (event.target.checked) {
      $(this.raceFieldTarget).show()
      $(this.raceRequiredTarget).show()
      $(this.raceLabelTarget).show()
    } else {
      $(this.raceFieldTarget).hide()
      $(this.raceRequiredTarget).hide()
      $(this.raceLabelTarget).hide()
    }
  }
  
  toggleEthnicityForm(event){
    if (event.target.checked) {
      $(this.ethnicityFieldTarget).show()
      $(this.ethnicityRequiredTarget).show()
      $(this.ethnicityLabelTarget).show()
    } else {
      $(this.ethnicityFieldTarget).hide()
      $(this.ethnicityRequiredTarget).hide()
      $(this.ethnicityLabelTarget).hide()
    }
  }
  
  toggleScreeningIdFields(event){
    if (event.target.checked) {
      $(this.screeningIdFieldsTarget).show()
      $("#screening_id_length").attr("required", true)
    } else {
      $(this.screeningIdFieldsTarget).hide()
      $("#screening_id_length").attr("required", false)
    }

  }
}
