import React from "react";
import truncate from "truncate";

const ResultWrapper = ({ result, children }) => (
  <a href={result.path}>
    <div className="search--result-header">{result.type}</div>
    {children}
  </a>
);

const WorkspaceResult = ({ workspace }) => (
  <ResultWrapper result={workspace}>
    {workspace.name} / {workspace.site.name}
  </ResultWrapper>
);
const InventoryResult = ({ inventory }) => (
  <ResultWrapper result={inventory}>
    {inventory.name}
    {" / "}
    {inventory.workspace?.sponsor_name && (
      <>{inventory.workspace.sponsor_name}</>
    )}
    {!inventory.workspace?.sponsor_name && <>{inventory.workspace.name}</>}
  </ResultWrapper>
);
const ItemResult = ({ item }) => (
  <ResultWrapper result={item}>
    {item.name} ({item.state}) / {item.inventory.name} / {item.workspace.name}
  </ResultWrapper>
);
const StorageAreaResult = ({ storageArea }) => (
  <ResultWrapper result={storageArea}>
    {storageArea.name} / {storageArea.site.name}
  </ResultWrapper>
);
const SampleShipmentResult = ({ sampleShipment }) => (
  <ResultWrapper result={sampleShipment}>
    {sampleShipment.name} ({sampleShipment.shipped_at})
  </ResultWrapper>
);
const SiteSampleShipmentResult = ({ siteSampleShipment }) => (
  <ResultWrapper result={siteSampleShipment}>
    {siteSampleShipment.name} ({siteSampleShipment.shipped_at})
  </ResultWrapper>
);
const VendorShipmentResult = ({ vendorShipment }) => (
  <ResultWrapper result={vendorShipment}>
    {vendorShipment.name} ({vendorShipment.shipped_at})
  </ResultWrapper>
);
const ReplenishmentResult = ({ replenishment }) => (
  <ResultWrapper result={replenishment}>
    {replenishment.name} ({replenishment.shipped_at})
  </ResultWrapper>
);
const SampleResult = ({ sample }) => (
  <ResultWrapper result={sample}>
    {sample.name} / {sample.sample_timepoint.name} / {sample.subject.name}
  </ResultWrapper>
);

const SampleGroupResult = ({ sampleGroup }) => (
  <ResultWrapper result={sampleGroup}>
    {sampleGroup.name} / {sampleGroup.sample_type.name}
  </ResultWrapper>
);
const SubjectResult = ({ subject }) => (
  <ResultWrapper result={subject}>
    {subject.subject_identifier} / {subject.workspace.name}
  </ResultWrapper>
);
const OrderResult = ({ order }) => (
  <ResultWrapper result={order}>
    {order.name} / {order.site.name} / {order.inventory.name}
  </ResultWrapper>
);
const ProtocolResult = ({ protocol }) => (
  <ResultWrapper result={protocol}>
    {protocol.name} / {protocol.sponsor.name}
  </ResultWrapper>
);
const SiteResult = ({ site }) => (
  <ResultWrapper result={site}>{site.name}</ResultWrapper>
);

export {
  WorkspaceResult,
  InventoryResult,
  ItemResult,
  StorageAreaResult,
  SampleShipmentResult,
  SiteSampleShipmentResult,
  VendorShipmentResult,
  ReplenishmentResult,
  SampleResult,
  SampleGroupResult,
  SubjectResult,
  OrderResult,
  ProtocolResult,
  SiteResult,
};
