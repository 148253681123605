import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scanner--create-item"
export default class extends Controller {
  static targets = ["inventoryField", "inventorySerialMismatch"];

  change({ detail }) {
    // If this isn't an existing option, display all fields and hide
    // possible error message.
    if (!detail["option"]) {
      this.inventoryFieldTargets.forEach((field) =>
        field.classList.remove("d-none")
      );

      if (this.hasInventorySerialMismatchTarget) {
        this.inventorySerialMismatchTarget.classList.add("d-none");
      }

      return;
    }

    /** @type {DOMStringMap} */
    const fields = detail["option"].dataset;

    this.inventoryFieldTargets.forEach((field) => {
      const fieldIsEnabled = fields[field.dataset.name] === "true";

      field.classList.toggle("d-none", !fieldIsEnabled);
      if (!fieldIsEnabled) {
        field.value = "";
      }
    });

    if (this.hasInventorySerialMismatchTarget) {
      this.inventorySerialMismatchTarget.classList.toggle(
        "d-none",
        fields.serialNumber === "true"
      );
    }
  }
}
