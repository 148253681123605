import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

const input_names = ["part_type", "force_numeric", "character_limit", "joiner_type", "part_value"]

export default class extends Controller {
  static values = {
    planId: String
  }
  
  static targets = ["partsContainer", "standardFieldsContainer"];

  connect(){
    this.initializeSortLogic()
  }
  
  initializeSortLogic(){
    const planId = this.planIdValue
    if ($("#parts-sortable").length > 0) {
      $("#parts-sortable").sortable({
        axis: "y",
        items: ".item",
        cursor: "move",
        sort: function (e, ui) {
          return ui.item.addClass("active-item-shadow");
        },
        stop: function (e, ui) {
          ui.item.removeClass("active-item-shadow");
          return ui.item.children("td").effect("highlight", {}, 1000);
        },
        update: function (e, ui) {
          var previewData = [];
          $("#parts-sortable").find(".part-card").each( (index, part_card) => {
            var part_card = $($(this).find(".part-card")[index])
            var current_part = {"index": index}
            
            // change id of card to new index:
            part_card.attr("id", `part_card_${index}`)

            // change name of various inputs on card to new index: 
            input_names.forEach(input_name => {
              var input = part_card.find(`[name*="${input_name}"]`)
              if (input.length > 0){
                var name = `${input_name}_${index}`
                part_card.find(`[name*="${input_name}"]`).attr("name", name)
                part_card.find(`[name*="${input_name}"]`).attr("id", name)
                if (["part_type", "joiner_type"].includes(input_name)){
                  current_part[input_name] = part_card.find(`[name*="${input_name}"]`)[0].value
                }
              }
            });
            previewData.push(current_part)
          })
          get(`/subject_identifier_plans/${planId}/update_plan_preview?part_data=${JSON.stringify(previewData)}`, { responseKind: 'turbo-stream' })
        },
      });
    }
  }

  updatePartFormTurboFrame(event) {
    var data = {}
    var partData = [];

    partData = this.gatherPartData(partData)

    data['partData'] = partData
    data['action'] = $(event.target)[0].getAttribute("action")
    data['target'] = $(event.target)[0].getAttribute("target")

    document.getElementById("plan_part_data_for_turbo").value = JSON.stringify(data)
    document.getElementById("plan-part-turbo-form").requestSubmit();
  }

  changePartType(event){
    const target_card = event.target.closest(".part-card").id
    var partData = JSON.stringify(this.gatherPreviewData([]))
    get(`/subject_identifier_plans/${this.planIdValue}/load_part_card?part_type=${event.target.value}&target_card=${target_card}&part_data=${partData}`, { responseKind: 'turbo-stream' })
  }
  
  updatePreview(event){
    var partData = JSON.stringify(this.gatherPreviewData([]))
    get(`/subject_identifier_plans/${this.planIdValue}/update_plan_preview?part_data=${partData}`, { responseKind: 'turbo-stream' })
  }

  gatherPartData(partData){
    $(this.partsContainerTarget).find('.part-card').each((index, part_card) => {
      var current_part = {}
      current_part["index"] = index
      input_names.forEach(input_name => {
        var input = document.getElementsByName(`${input_name}_${index}`)[0]
        if (input != undefined ) {
          current_part[input_name] = input.value
        }
      });
      partData.push(current_part);
    });
    return partData
  }

  gatherPreviewData(data){
    $(this.partsContainerTarget).find('.part-card').each((index, part_card) => {
      var current_part = {}
      current_part["index"] = index
      var part_type_input = document.getElementsByName(`part_type_${index}`)[0]
      if (part_type_input != undefined ) {
        current_part["part_type"] = part_type_input.value
      }
      if (document.getElementsByName(`joiner_type_${index}`).length > 0) {
        current_part["joiner_type"] = document.getElementsByName(`joiner_type_${index}`)[0].value
      }
      data.push(current_part);
    });
    return data
  }
}
